import * as convert from "xml-js";
import conditionXML from "./testData/diagnosis_cSection";
import moment from "moment";

const code_dict = require('./code_dict.json');
const code_dict_Cseciton = require('./code_dict_Csection.json');
const code_dict_blur_history = require('./code_dict_blur_history.json');
const code_dict_blur_anxiety = require('./code_dict_blur_anxiety.json');

// parse condition data
const parseCondition = (condition_xml, pregnancyStart, pregnancyEnd) => {
    const res = JSON.parse(
        convert.xml2json(condition_xml, { compact: true, spaces: 4 })
    );
    const entryList = res.Bundle.entry;
    let diagnosisDate = [];

    // get all conditions from fhir
    for (let i = 0; i < entryList.length; i++) {
        let condition = entryList[i].resource.Condition;
        if(condition) {
            if(Array.isArray(condition.code.coding)){
                for (let j = 0; j < condition.code.coding.length; j++) {
                    if(condition.onsetPeriod || condition.recordedDate){
                        const code = condition.code.coding[j].code._attributes.value;
                        const start = condition.onsetPeriod ? condition.onsetPeriod.start._attributes.value : condition.recordedDate._attributes.value;
                        const end = condition.onsetPeriod ? condition.onsetPeriod.end._attributes.value : null;
                        diagnosisDate.push({code: code, start: start, end: end});
                    } else {
                        const code = condition.code.coding[j].code._attributes.value;
                        diagnosisDate.push({code: code});
                    }
                }
            } else {
                const code = condition.code.coding.code._attributes.value;
                const start = condition.onsetPeriod ? condition.onsetPeriod.start._attributes.value : condition.recordedDate._attributes.value;
                const end = condition.onsetPeriod ? condition.onsetPeriod.end._attributes.value : null;
                diagnosisDate.push({code: code, start: start, end: end});
            }
        }
    }  

    console.log(diagnosisDate)

    let feature_dict = {}

    //compare with code_dict to see if there's any match, if yes, return 1, apply not history rule
    for (let i = 0; i < Object.keys(code_dict).length; i++) {
        const code = Object.keys(code_dict)[i];
        const conditionObj = diagnosisDate.find(o => o.code === code);
        if (conditionObj) {
            let conditionDateStart = conditionObj.start;
            let conditionDateEnd = conditionObj.end;
            if (
                (conditionDateStart) &&
                (
                    (moment(conditionDateStart).isBefore(moment(pregnancyStart)) && !conditionDateEnd ) 
                    || moment(conditionDateStart).isAfter(moment(pregnancyStart))
                )
                && diagnosisDate.map(o => o.code).includes(code)
            ) {
                feature_dict[code_dict[code]] = 1;
            }
        } 
    }

    // if no match, return 0
    for (let i = 0; i < Object.keys(code_dict).length; i++) {
        const code = Object.keys(code_dict)[i];
        if (!feature_dict.hasOwnProperty(code_dict[code])) {
            feature_dict[code_dict[code]] = 0;
        }
    }

    // code_dict_blur only match first letters , apply history value rule
    for (let i = 0; i < Object.keys(code_dict_blur_history).length; i++) {
        const code = Object.keys(code_dict_blur_history)[i];
        if (diagnosisDate.map(o => o.code).find(el => el.slice(0, code.length) === code)) {
            let conditionObj = diagnosisDate.find(o => o.code.slice(0, code.length) === code);
            let conditionDateStart = conditionObj.start;
            let conditionDateEnd = conditionObj.end;
            if ( moment(conditionDateStart).isBefore(moment(pregnancyStart)) && moment(conditionDateEnd).isBefore(moment(pregnancyEnd))) {
                feature_dict[code_dict_blur_history[code]] = 1;
            }
        } 
    }

    //if no match for code_dict_blur, return 0
    for (let i = 0; i < Object.keys(code_dict_blur_history).length; i++) {
        let code = Object.keys(code_dict_blur_history)[i];
        if (!feature_dict.hasOwnProperty(code_dict_blur_history[code])) {
            feature_dict[code_dict_blur_history[code]] = 0;
        }
    }

    // code_dict_blur_anxiety only match first letters , apply not history rule
    for (let i = 0; i < Object.keys(code_dict_blur_anxiety).length; i++) {
        const code = Object.keys(code_dict_blur_anxiety)[i];
        if (diagnosisDate.map(o => o.code).find(el => el.slice(0, code.length) === code)) {
            console.log(diagnosisDate.map(o => o.code).find(el => el.slice(0, code.length) === code))
            let conditionObj = diagnosisDate.find(o => o.code.slice(0, code.length) === code);
            console.log(conditionObj)
            let conditionDateStart = conditionObj.start;
            let conditionDateEnd = conditionObj.end;
            if ( 
                (moment(conditionDateStart).isBefore(moment(pregnancyStart)) && !conditionDateEnd ) 
                || moment(conditionDateStart).isAfter(moment(pregnancyStart))
            ) {
                feature_dict[code_dict_blur_anxiety[code]] = 1;
            }
        } 
    }

    //if no match for code_dict_blur_anxiety, return 0
    for (let i = 0; i < Object.keys(code_dict_blur_anxiety).length; i++) {
        let code = Object.keys(code_dict_blur_anxiety)[i];
        if (!feature_dict.hasOwnProperty(code_dict_blur_anxiety[code])) {
            feature_dict[code_dict_blur_anxiety[code]] = 0;
        }
    }

    // parse C-section differently since C-section somestimes doesn't have a date and no need to compare with pregnancyStart or pregannacyEnd
    for (let i = 0; i < Object.keys(code_dict_Cseciton).length; i++) {
        const code = Object.keys(code_dict_Cseciton)[i];
        if (diagnosisDate.map(o => o.code).includes(code)) {
            feature_dict[code_dict_Cseciton[code]] = 1;
        }
    }
    
    return feature_dict;
}

//get diagnosis from FHIR server
const getDiagnosis = async (myApp, pregnancyStart, pregnancyEnd) => {
    const URL = myApp.smart.state.serverUrl+"/Condition?patient="+myApp.smart.patient.id+"&episode-of-care=eiTE4zGhm7h0QWN4SAkVk-A3&clinical-status=active";

    const res = await fetch(URL, {
        headers: {
            authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });
    const conditionXML = await res.text();

    const conditionJSON = parseCondition(conditionXML, pregnancyStart, pregnancyEnd);
    return conditionJSON;
};

export default getDiagnosis;