import moment from "moment";
import * as convert from "xml-js";
import bloodPressureSample from './testData/bloodPressure';

const parseBloodPressure = (xml, pregnancyStart, pregnancyEnd) => {
    var res = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 4 })
    );
    const entryList = res.Bundle.entry;
    let bloodPressure = 0;
    let count = 0;
    for (let i = 0; i < entryList.length; i++) {
        let observation = entryList[i].resource.Observation
        if(observation && observation.code.text._attributes.value === 'BP' && observation.component) {
            let components = observation.component;
            if(Array.isArray(components)) {
                for(let j = 0; j < components.length; j++){
                    if (components[j].code.text._attributes.value === 'Diastolic blood pressure') {
                        let measureDate = observation.effectiveDateTime._attributes.value;
                        if(moment(measureDate).isAfter(moment(pregnancyStart)) && (pregnancyEnd ? moment(measureDate).isBefore(moment(pregnancyEnd)) : true)) {
                            count += 1;
                            let newValue = Number(components[j].valueQuantity.value._attributes.value);
                            bloodPressure = bloodPressure*(count - 1)/count + newValue/count;
                        }
                    }
                }
            } else if(components) {
                if (components.code.text._attributes.value === 'Diastolic blood pressure') {
                    let measureDate = observation.effectiveDateTime._attributes.value;
                    if(moment(measureDate).isAfter(moment(pregnancyStart)) && (pregnancyEnd ? moment(measureDate).isBefore(moment(pregnancyEnd)) : true)) {
                        count += 1;
                        let newValue = Number(components.valueQuantity.value._attributes.value);
                        bloodPressure = bloodPressure*(count - 1)/count + newValue/count;
                    }
                }
            }
        }
    }
    return {blood_pressure: bloodPressure ? bloodPressure : ''};
};

const getBloodPressure = async (myApp, pregnancyStart, pregnancyEnd) => {
    const URL =
        myApp.smart.state.serverUrl +
        "/Observation?subject=Patient/" +
        myApp.smart.patient.id +
        "&category=vital-signs";

    const res = await fetch(URL, {
        headers: {
            // Accept: "application/json+fhir",
            Authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });
    const result = await res.text();
    const bloodPressure = parseBloodPressure(result, pregnancyStart, pregnancyEnd);
    return bloodPressure;
};

export default getBloodPressure;