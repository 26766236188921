import * as convert from "xml-js";
import code_dict_med from './code_dict_med.json';
import Medication from "./testData/medication";

// parse medication code
const parseCodeInfo = (xml) => {
    const res = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 4 })
    );
    const codeList = res.Medication && res.Medication.code && res.Medication.code.coding;
    let codeRes;
    for (let i = 0; i < Object.keys(code_dict_med).length; i++) {
        const code = Object.keys(code_dict_med)[i];
        if (codeList && Array.isArray(codeList) && codeList.map(o => o.code._attributes.value).includes(code)) {
            codeRes = code
        } else if(codeList.code && codeList.code._attributes.value === code) {
            codeRes = code
        }
    }
    return codeRes
}

// get medication code with referenceID
const getMedicationCode = async (myApp, ID) => {
    const URL =
        myApp.smart.state.serverUrl + ID;

    const res = await fetch(URL, {
        headers: {
            // Accept: "application/json+fhir",
            Authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });

    const codeInfo = await res.text();
    const codeRes = parseCodeInfo(codeInfo);

    // const codeRes = parseCodeInfo(Medication);
    return codeRes;   
}

export default getMedicationCode;