import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter, Redirect} from "react-router-dom";
import { connect } from 'react-redux';

import { checklogin } from '../global/action';

// import ProtectedRoute from "./ProtectedRoute";
import CssBaseline from "@material-ui/core/CssBaseline";
// different routes
import Home from "../pages/home";
import Login from "../pages/login";
import Register from '../pages/register/register';
import ManuallyInput from '../pages/register/manuallyInput';
import Consent from '../pages/register/consent';
import Topic from '../pages/register/category';
import DetailPage from '../pages/blogDetail';
import About from '../pages/about';
import Profile from '../pages/profile';
// import Signup from "../screens/Signup";
// import CreatePost from "../screens/CreatePost.js";
// import Profile from "../screens/Profile";
// import UserProfile from "../screens/UserProfile";

import Launch from "../pages/register/launch";

const Routing = props => {
	const { dispatch } = props

	useEffect(() => {
		dispatch(checklogin())
	}, [])

	return (
		<BrowserRouter> 
			<CssBaseline />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/manually-input" component={ManuallyInput} />
				<Route exact path="/consent" component={Consent} />
				<Route exact path="/category" component={Topic} />
				<Route path="/detail" component={DetailPage} />
				<Route path="/launch" component={Launch} />
				<Route path="/about" component={About} />
				<Route path="/profile" component={Profile} />
				<Redirect to="/" />

				{/* in case we want to handle the 404 page not found */}
				{/* <Route component={NotFound} /> */}
			</Switch>
		</BrowserRouter>
	);
};

const mapStoreStateToProps = (state) => {
    const loggedin = state.loggedin;
    return {
        loggedin
    };
};


export default connect(mapStoreStateToProps)(Routing)
