import * as convert from "xml-js";
import patient from "./testData/patient";

export const raceType = {
  Unknown: ['Unknown-Pt not Avail', 'Other Not Described', 'Declined', 'O', 'D', 'Unknown', 'nan'],
  White: ['White', 'W'],
  Asian: ['Asian', 'A', 'Asian Indian', 'Chinese', 'Indonesian', 'Bangladeshi', 'Korean', 'Japanese', 'Vietnamese', 'Pakistani', 'Laotian', 'Okinawan', 'Filipino', 'Cambodian', 'Thai', 'Malaysian', 'Burmese', 'Taiwanese', 'Bhutanese', 'Nepalese', 'Sri lankan'],
  Black: ['Black/African Am.', 'B', 'Black'],
  Other: ['Na Hawaii/Pac Island', 'Other Pacif Islander', 'Fijian', 'Saipanese', 'Guamanian', 'P', 'Papua New Guinean', 'Palauan', 'Yapese', 'Tahitian', 'Native Hawaiian', 'Tongan', 'New Hebrides','Guamanian/Chamorro', 'Samoan', 'Polynesian', 'Chuukese', 'Am Indian/Alaska Nat']
}

// parse patient data
const parsePatient = (patient_xml) => {
    const feature_dict = {};
    let res_patient;
    try {
        res_patient = JSON.parse(
          convert.xml2json(patient_xml, { compact: true, spaces: 4 })
        );
      } catch (error) {
        console.error(`Error parsing patient XML: ${error}`);
        return null;
    }

    let result = {};

    //set race value
    let race = "";
    if (Array.isArray(res_patient.Patient.extension[0].extension)) {
      const lastExtension = res_patient.Patient.extension[0].extension[res_patient.Patient.extension[0].extension.length - 1];
      if (lastExtension.valueString) {
        race = lastExtension.valueString._attributes.value;
      }
    } else {
      if (res_patient.Patient.extension[0].extension.valueString) {
        race =
          res_patient.Patient.extension[0].extension.valueString._attributes
            .value; 
      }
    }
    //decide race value basecd on raceType
    for (let i = 0; i < Object.keys(raceType).length; i++) {
      const raceCategory = Object.keys(raceType)[i];
      if (raceType[raceCategory].includes(race)){
          race = raceCategory;
      }
    }
    // if race value is not included in raceType array, set race as other
    if(!Object.keys(raceType).includes(race)) {
        race = 'Other';
    }

    //set marital status value
    const maritalStatus = res_patient.Patient.maritalStatus ?
        res_patient.Patient.maritalStatus.text._attributes.value : '';

    if (race.toLowerCase() === "asian") {
        feature_dict["asian"] = 1;
    } else {
        feature_dict["asian"] = 0;
    }
    if (race.toLowerCase() === "white") {
        feature_dict["white"] = 1;
    } else {
        feature_dict["white"] = 0;
    }
    if (maritalStatus.toLowerCase() === "single") {
        feature_dict["single"] = 1;
    } else {
        feature_dict["single"] = 0;
    }
    result.patient = res_patient.Patient;
    result.feature_dict = feature_dict;
    return result;
};

// get patient data
const getPatient = async (myApp) => {
    const URL =
    myApp.smart.state.serverUrl + "/Patient/" + myApp.smart.patient.id;
    const res = await fetch(URL, {
        headers: {
            authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });
    let patientXML = await res.text();
    const patientResult = parsePatient(patientXML);
    return patientResult;
};

export default getPatient;