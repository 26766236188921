import React, { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

// Material-UI Components
import { InputAdornment, IconButton, Button, Grid, Container, Snackbar, TextField} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import logo from '../assets/logo/specialdays.png';
import { login } from "../global/action";
import ExampleImg1 from '../assets/background/leaf_bg.jpg';

// General Styles
const useStyles = makeStyles((theme) => ({
	logo: {
		margin: '60px auto 0'
	},
	paper: {
		marginTop: "50px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		'& .MuiButton-fullWidth': {
            backgroundColor: '#2F80ED',
            color: '#fff'
        },
        '& .Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)'
        }
	},
	image: {
		backgroundSize: "cover",
		backgroundColor: "#fafafa",
		backgroundImage: `url(${ExampleImg1})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		height: "100vh",
	},
	text: {
		color: '#333',
		margin: '50px auto 30px',
		fontSize: 16,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(2, 0, 2),
	},
}));

const Login = props => {

    const history = useHistory();
	const classes = useStyles();

	const [email, setEmail] = useState("");

	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");

	const handleSubmit = e =>{
		e.preventDefault();
		props.dispatch(login({
			user_name: email,
			password: password
		})).then(()=>{
			console.log('login Success')
			setOpenMessage(true)
			setMessageType('success')
			setMessage('Log in successfully!');
			history.push('/')
		}).catch(err => {
			console.log(err)
			setOpenMessage(true)
			setMessageType('error')
			setMessage(err);
		})
	}

	return (
		<Grid container>
			<Grid className={classes.image} item sm={4} md={6} />
			<Grid item xs={12} sm={8} md={6} >
				<Container component="main" maxWidth="xs">
					<div className={classes.paper}>
						<div className={classes.logo}>
                            <img src={logo} alt="test" style={{width: 200}}/>
                        </div>
						<p className={classes.text}>
							{window.location.search.includes('success') && 'You have successfully registered! Please sign in here!'}
						</p>                                                                  
						<form className={classes.form} onSubmit={handleSubmit}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email or username"
								name="email"
								autoFocus
								value={email}	
								onChange={e => setEmail(e.target.value)}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								autoComplete="current-password"
								value={password}
								onChange={e => setPassword(e.target.value)}
								type = {showPassword ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => setShowPassword(!showPassword)}
												onMouseDown={e => e.preventDefault()}
											>
											{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
									  	</InputAdornment>
									)
								}}
							/>
							<Button
								fullWidth
								variant="outlined"
								color="primary"
								className={classes.submit}
								disabled={!(email.length > 3 && password.length > 2) }
								type="submit"
							>
								Sign In
							</Button>
							<Grid container justifyContent="center">
                                <span style = {{marginRight: 10}}>Don't have an account?</span>
								<Link to="/register" style={{ textDecoration: "none" }}>
									{"Sign Up"}
								</Link>
							</Grid>
							<Snackbar
								open={openMessage}
								autoHideDuration={3000}
								anchorOrigin = {{
									vertical: 'bottom',
									horizontal: 'right'
								}}
							>
								<Alert severity={messageType}>{message}</Alert>
							</Snackbar>
						</form>
					</div>
				</Container>
			</Grid>
		</Grid>
	);
};

const mapStoreStateToProps = (state) => {
    const loggedin = state.loggedin;
    return {
        loggedin
    };
};



export default connect(mapStoreStateToProps)(withRouter(Login));
