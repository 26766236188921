import * as ActionTypes from '../constant/actionType';
import { fromJS } from 'immutable';

const defaultState = fromJS({
  blogData: [],
  count: 10,
  size: 10
});

const blogReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GETBLOG:
      return state.set('blogData', action.payload);

    case ActionTypes.SETCOUNT:
          return state.set('count', action.payload);
    
    case ActionTypes.SETPAGESIZE:
            return state.set('size', action.payload);

    default:
      return state;
  }
}

export default blogReducer;