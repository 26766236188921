import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

// Material-UI Components
import {  
    Button, 
    Grid, 
    Container,
	Snackbar
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from "@material-ui/core/styles";
import logo from '../../assets/logo/specialdays.png';
import ExampleImg1 from '../../assets/background/leaf_bg.jpg';
import { getCategory } from "../../SDK/selectOptions";
import { getUserInfo } from "../../global/action";
import { updateUserInfo } from "../../SDK/user";

// General Styles
const useStyles = makeStyles((theme) => ({
	logo: {
		margin: '60px auto 20px',
		paddingLeft: 10,
		width: 200
	},
	paper: {
		marginTop: "50px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		'& .MuiButton-fullWidth': {
            backgroundColor: '#2F80ED',
            color: '#fff'
        },
		textAlign: 'center'
	},
	image: {
		backgroundSize: "cover",
		backgroundColor: "#fafafa",
		backgroundImage: `url(${ExampleImg1})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		height: "100vh",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		// marginTop: theme.spacing(1),
	},
	tag: {
		border: '1px solid #2F80ED',
		color: '#2F80ED',
		borderRadius: 50,
		padding: '7px 15px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 4
	}, 
	tagIcon: {
		color: '#fff'
	},
	text: {
		color: '#333',
		margin: '50px auto 30px',
		fontSize: 16,
	},
	submit: {
		margin: '100px auto',
        display: 'block',
        width: 200
	},
}));

const Category = props => {
    const history = useHistory();
	const classes = useStyles();
	const [category, setCategory] = useState([]);
	const [categoryList, setCategoryList] = useState([]);

	const [message, setMessage] = useState("");
    const [openMessage, setOpenMessage] = useState(false);
    const [messageType, setMessageType] = useState("");

	useEffect(() => {
		getCategory()
		.then(({response}) => {
			setCategoryList(response);
			props.dispatch(getUserInfo());
		})
	}, [])

	const handleSubmit = () => {
		const { user } = props; 
		let body = {
			_id: user._id,
			user_id: user.user_id,
			account_id: null,
			photo: null,
			full_name: user.full_name,
			category: [...new Set(user.category.concat(category))],
			race: user.race,
			marriage: user.marriage,
			mental: user.mental,
			symptom: user.symptom,
			blood_pressure:  user.blood_pressure,
			visits: user.visits
		}

		updateUserInfo(body)
		.then(({response}) => {
			console.log(response)
			setOpenMessage(true)
			setMessageType('success')
			setMessage('Success!');
			props.dispatch(getUserInfo());
			if (localStorage.login === 'success'){
				history.push('/');
				localStorage.removeItem('login')
			} else {
				history.push('/login?register=success')
			}
		})
		.catch(err => {
			console.log(err)
			setOpenMessage(true)
			setMessageType('error')
			setMessage(err.message);
		})
	}

	return (
		<>
			<Grid container>
				<Grid className={classes.image} item sm={4} md={6} />
				<Grid item xs={12} sm={8} md={6}>
					<Container component="main" maxWidth="xs">
						<div className={classes.paper}>
							<div className={classes.logo}>
								<img src={logo} alt="test" style={{width: '90%'}}/>
							</div>                                                                  
							<form className={classes.form} noValidate>
								<p className={classes.text}>
									{'What health-related topic are you interested in learning about? '}
								</p>
								<div style={{display:'flex', flexWrap: 'wrap', gap: 12, justifyContent: 'center'}}>
									{
										category && categoryList.map((item, index) => (
											<div
												className={classes.tag}
												style={category.includes(item) ? {cursor: 'pointer', backgroundColor:'#2F80ED', color: '#fff'} : {cursor : 'pointer'}}
												key={index}
												onClick = {() => {
													category.includes(item) ? setCategory((prevState) => prevState.filter(el => el !== item)) : setCategory((prevState) => prevState.concat([item]))
												}}
											>
												<span>{item.replace(/_/g, ' ')}</span>
												{
													category.includes(item) && <DoneIcon className={classes.tagIcon}/>
												}
											</div>
										))
									}
								</div>
								<Button
									onClick={handleSubmit}
									fullWidth
									className={classes.submit}
									variant="contained"
								>
									Confirm
								</Button>
							</form>
						</div>
					</Container>
				</Grid>
			</Grid>
			<Snackbar
				open={openMessage}
				autoHideDuration={3000}
				anchorOrigin = {{
					vertical: 'bottom',
				    horizontal: 'right'
				}}
			>
				<Alert severity={messageType}>{message}</Alert>
			</Snackbar>
		</>
	);
};

const mapStoreStateToProps = (state, props) => {
    return { 
        user: state.userReducer.toJS().user,
    };
};

export default connect(
    mapStoreStateToProps
)(Category);