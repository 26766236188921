import * as convert from "xml-js";
import pregnancy from "./testData/pregancyDateExample";

// parse pregnancy date data
const parsePregnancyDate = (xml) => {
    const pregnancyStatus = JSON.parse(
      convert.xml2json(xml, { compact: true, spaces: 4 })
    );
  
    const period = pregnancyStatus.Bundle.entry.resource.EpisodeOfCare && pregnancyStatus.Bundle.entry.resource.EpisodeOfCare.period;
     
    const start = period && period.start && period.start._attributes && period.start._attributes.value;
    const end = period && period.end && period.end._attributes && period.end._attributes.value;
  
    console.log(period)
    var Date = [start, end];
    console.log(Date)
    return Date;
  };

//get the pregnancy date of the patient
const getPregnancyDate = async (myApp) => {
    const URL =
        myApp.smart.state.serverUrl +
        "/EpisodeOfCare?patient=" + 
        myApp.smart.patient.id 
        + 
        "&type=urn:oid:1.2.840.114350.1.13.535.3.7.2.726668|2";

    const res = await fetch(URL, {
        headers: {
            // Accept: "application/json+fhir",
            Authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });
    const pregnancy = await res.text();

    const pregnancyDate = parsePregnancyDate(pregnancy);
    return pregnancyDate;
};

export default getPregnancyDate;
