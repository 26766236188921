import React, { useEffect, useRef, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import _debounce from "lodash/debounce";
import {
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import moment from "moment";

import {
  likeBlog,
  unlikeBlog,
  recordScanHistory,
  getContent,
  commentBlog,
} from "../SDK/blog";
import { getBlogData, getUserBlogs } from "../global/action";
import { useOnScreen } from "../utils/customHook";
import { randomImageList } from "./AddPostModal";
import { max } from "lodash";

const useStyles = makeStyles((theme) => ({
  base: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiPaper-rounded": {
      borderRadius: 0,
    },
    marginBottom: 10,
  },
  root: {
    // minWidth: 480,
    cursor: "pointer",
    backgroundColor: "#fff",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up("xs")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.up("md")]: {
      width: "450px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInput-underline": {
      paddingBottom: 20,
      paddingRight: 50,
      "&:before": {
        borderBottom: "1px solid rgba(0,0,0,0.25)",
      },
      "&:after": {
        borderBottom: "1px solid rgba(0,0,0,0.30)",
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid rgba(0,0,0,0.30)",
      },
    },
    "& .MuiIconButton-root": {
      transform: "translate(-10px, 0)",
    },
  },
  header: {
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
  },
  // media: {
  //   // paddingTop: "56.25%" /* 16:9 */,
  //   // height: 0,
  //   width: "100%",
  //   maxHeight: "500px",
  //   // overflow: "hidden",
  //   objectFit: "contain",
  //   position: "relative",
  //   "& video": {
  //     paddingTop: "56.25%" /* 16:9 */,
  //     width: "100%",
  //     height: "100%",
  //     position: "absolute",
  //     top: 0,
  //     left: 0,
  //   },
  //   "& img": {
  //     objectFit: "contain",
  //     width: "100%",
  //   },
  // },
  media: {
    // paddingTop: "80%",
    // height: "max-content",
    width: "100%",
    height: "500px", // 临时设置一个明确的高度来测试
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  likeBar: {
    height: 30,
    paddingTop: "0px",
    marginTop: 15,
    marginBottom: 8,
    paddingLeft: "0px",
    paddingBottom: "4px",
  },
  comments: {
    display: "flex",
    paddingTop: "0px",
    alignItems: "flex-end",
    position: "relative",
  },
  comment_item_see_more: {
    width: "35%",
    cursor: "pointer",
  },
  comments_icon_see_more: {
    height: "17px",
    width: "17px",
    paddingTop: "4px",
    paddingBottom: "3px",
  },
  comments_icon: {
    height: "30px",
    paddingLeft: "0px",
    paddingTop: "13px",
    paddingRight: "8px",
    paddingBottom: "0px",
  },
  inline: {
    display: "inline",
    fontWeight: "600",
  },
  avatar: {
    height: "40px",
  },
  links: {
    textDecoration: "none",
  },
  detailGroup: {
    marginBottom: 10,
    marginTop: 7,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  more: {
    color: "rgba(0,0,0,0.5)",
    "&:hover": {
      color: "rgba(0,0,0,0.8)",
    },
  },
  post: {
    color: "#2F80ED",
    position: "absolute",
    right: 0,
    bottom: 20,
  },
  commentBox: {
    display: "flex",
    gap: 8,
  },
  strong: {
    fontWeight: 600,
  },
}));

export const randomPhoto = randomImageList[1];

const DisplayCard = (props) => {
  const { item, user, dispatch, size, userBlogSize } = props;

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef();
  const addScanHistory = useOnScreen(ref);
  const [content, setContent] = useState(null);
  const [comment, setComment] = useState("");

  const handleEndScroll = useMemo(
    () =>
      _debounce(() => {
        if (window.location.pathname === "/" && addScanHistory) {
          recordScanHistory(item._id);
        }
      }, 1000),
    [addScanHistory, item._id]
  );

  useEffect(() => {
    window.onscroll = () => handleEndScroll();
    return () => (window.onscroll = null);
  });

  const unlikePost = (id) => {
    unlikeBlog(id).then(() => {
      dispatch(getBlogData(size));
      dispatch(getUserBlogs(userBlogSize));
    });
  };

  const likePost = (id) => {
    likeBlog(id).then(() => {
      dispatch(getBlogData(size));
      dispatch(getUserBlogs(userBlogSize));
    });
  };

  const showContent = (id) => {
    getContent(id).then((res) => {
      setContent(res.response.content);
    });
  };

  const submitComment = (id) => {
    let body = {
      blog_id: id,
      message: comment,
    };
    commentBlog(body).then(() => {
      dispatch(getBlogData(size));
      dispatch(getUserBlogs(userBlogSize));
      setComment("");
    });
  };

  return (
    <div className={classes.base}>
      <Card className={classes.root} ref={ref} style={{ margin: "10px auto" }}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar>
              <img
                className={classes.avatar}
                alt=""
                src={`${item.posted_by.photo}`}
              />
            </Avatar>
          }
          title={item.posted_by.username}
          subheader={moment(item.created_at).format("MMMM Do YYYY")}
          onClick={() => history.push(`/detail/${item._id}`)}
        />
        <CardMedia
          className={classes.media}
          title="Media Content"
          onClick={() => history.push(`/detail/${item._id}`)}
        >
          {item.videos && item.videos.length > 0 ? (
            <video controls src={item.videos[0]}>
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={item.photos[0] || randomPhoto} alt="Photo" />
          )}
        </CardMedia>

        <CardActions className={classes.likeBar} disableSpacing>
          <div>
            {item.likes.includes(user.user_id) ? (
              <IconButton
                aria-label="Like"
                style={{ color: "#f50022" }}
                onClick={() => unlikePost(item._id)}
              >
                <FavoriteIcon style={{ fontSize: "1.7rem" }} />
              </IconButton>
            ) : (
              <IconButton aria-label="Like" onClick={() => likePost(item._id)}>
                <FavoriteBorderIcon style={{ fontSize: "1.7rem" }} />
              </IconButton>
            )}
            <IconButton
              aria-label="Like"
              onClick={() => history.push(`/detail/${item._id}`)}
            >
              <ChatBubbleOutlineIcon style={{ fontSize: "1.5rem" }} />
            </IconButton>
          </div>
        </CardActions>
        <div style={{ display: "flex", gap: 10 }}>
          <div className={classes.strong}>
            {`${item.likes.length} ${item.likes.length > 1 ? "Likes" : "Like"}`}
          </div>
        </div>
        <div className={classes.detailGroup}>
          <div>{item.title}</div>
          {content && (
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              // style={{marginTop: -8, marginBottom: -8}}
            ></div>
          )}
          <div className={classes.more}>
            {!content ? (
              <span onClick={() => showContent(item.body)}>More</span>
            ) : (
              <span onClick={() => setContent(null)} style={{ marginTop: -8 }}>
                Hide content
              </span>
            )}
          </div>
          {item.last_comment && (
            <>
              <div>
                <span className={classes.strong}>
                  {`@${item.last_comment.posted_by.username}`}
                </span>
                <span style={{ marginLeft: 8 }}>
                  {item.last_comment.message}
                </span>
              </div>
              <div
                className={classes.more}
                onClick={() => history.push(`/detail/${item._id}`)}
              >
                View all comments
              </div>
            </>
          )}
        </div>
        <div className={classes.comments}>
          <TextField
            multiline
            // rows={1}
            placeholder="Add your comment..."
            variant="standard"
            value={comment}
            onChange={(event) => {
              event.preventDefault();
              setComment(event.target.value);
            }}
          />
          {comment && (
            <div
              className={classes.post}
              onClick={() => submitComment(item._id)}
            >
              POST
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

const mapStoreStateToProps = (state, props) => {
  return {
    user: state.userReducer.toJS().user,
    userBlogSize: state.userReducer.toJS().userBlogSize,
    size: state.blogReducer.toJS().size,
  };
};

export default connect(mapStoreStateToProps)(DisplayCard);
