import * as ActionTypes from "../constant/actionType";
import user from "../../SDK/request";
import * as blogApi from "../../SDK/blog";
import * as userApi from "../../SDK/user";

//authLogin

export const checklogin = () => (dispatch) => {
  return user.checkLogin().then((logged) => {
    console.log(logged);
    dispatch({
      type: ActionTypes.LOGIN_CHECKED,
      logged,
    });
  });
};

export const login =
  ({ user_name, password }) =>
  (dispatch) => {
    return user
      .login(user_name, password)
      .then(() => {
        dispatch({
          type: ActionTypes.LOGIN,
        });
        return true;
      })
      .catch((err) => {
        throw err.message;
      });
  };

export const logout = () => (dispatch) => {
  return user.logout().then((success) => {
    if (success) {
      dispatch({
        type: ActionTypes.LOGOUT,
      });
      console.log("log out");
    }
    return success;
  });
};

export const register = ({ email, password, username }) => {
  return user.register(email, password, username).then((response) => response);
};

export const getBlogData = (size) => (dispatch, getState) => {
  return blogApi.getAllBlogs(size).then((res) => {
    if (res) {
      let { response, headers } = res;
      dispatch({
        type: ActionTypes.GETBLOG,
        payload: response,
      });
      dispatch({
        type: ActionTypes.SETCOUNT,
        payload: Number(headers.get("X-Total-Count")),
      });
    }
  });
};

export const changeBlogPageSize = (size) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SETPAGESIZE,
    payload: size,
  });
};

export const getUserInfo = () => (dispatch, getState) => {
  return userApi.getUserInfo().then((res) => {
    if (res) {
      let { response } = res;
      dispatch({
        type: ActionTypes.GETUSERINFO,
        payload: response.user_info,
      });
    }
  });
};

export const getUserBlogs = (size) => (dispatch, getState) => {
  let blogSize = size % 2 === 0 ? size : size + 1;
  return blogApi.getCurrentUserBlog(blogSize).then((res) => {
    if (res) {
      let { response, headers } = res;
      dispatch({
        type: ActionTypes.GETUSER_BLOGS,
        payload: response,
      });
      dispatch({
        type: ActionTypes.UPDATEUSER_BLOG_COUNT,
        payload: Number(headers.get("X-Total-Count")),
      });
    }
  });
};

export const changeUserBlogSize = (size) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SETUSER_BLOG_SIZE,
    payload: size,
  });
};

export const UpdateModalStatus = (status) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UPDATEMODAL_STATUS,
    payload: status,
  });
};

export const setBlogUpdateTrigger = (trigger) => ({
  type: ActionTypes.SET_BLOG_UPDATE_TRIGGER,
  payload: trigger,
});
