//auth
export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGIN_CHECKED = "login_checked";
export const ADD_MESSAGE = "add_message";
export const REMOVE_MESSAGE = "remove_message";

//user
export const GETUSERINFO = "GETUSERINFO";
export const GETUSER_BLOGS = "GETUSER_BLOGS";
export const UPDATEUSER_BLOG_COUNT = "UPDATEUSER_BLOG_COUNT";

//blog
export const GETBLOG = "GETBLOG";
export const SETPAGESIZE = "SETPAGESIZE";
export const SETCOUNT = "SETCOUNT";
export const SETUSER_BLOG_SIZE = "SETUSER_BLOG_SIZE";

//page
export const UPDATEMODAL_STATUS = "UPDATEMODAL_STATUS";
export const SET_BLOG_UPDATE_TRIGGER = "SET_BLOG_UPDATE_TRIGGER";
