import authRequest from "./request";

//上传图片
export const uploadToServer = (data) => {
  const config = {
    method: "POST",
    headers: {},
    body: data,
  };
  return authRequest.send("/upload-file", config);
};

export const createBlog = (content) => {
  const config = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(content),
  };
  return authRequest.send("/blogs", config);
};

export const adminCreateBlog = (content) => {
  const config = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(content),
  };
  return authRequest.send("/blogs/admin", config);
};

export const getAllBlogs = (size) => {
  const config = {
    method: "GET",
    headers: {},
  };
  return authRequest.send(`/blogs?page=0&size=${size}`, config);
};

export const getBlogDetail = (id) => {
  const config = {
    method: "GET",
    headers: {},
  };
  return authRequest.send(`/blog?blog_id=${id}`, config);
};

export const likeBlog = (id) => {
  const token = JSON.parse(localStorage.token);
  const config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token.access_token,
    },
  };
  return authRequest.send(`/blogs/like?id=${id}`, config);
};

export const unlikeBlog = (id) => {
  const token = JSON.parse(localStorage.token);
  const config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token.access_token,
    },
  };
  return authRequest.send(`/blogs/unlike?id=${id}`, config);
};

export const commentBlog = (content) => {
  const config = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(content),
  };
  return authRequest.send("/comments", config);
};

export const replyComment = (content) => {
  const config = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(content),
  };
  return authRequest.send("/replies", config);
};

export const getCurrentUserBlog = (size) => {
  const config = {
    method: "GET",
    headers: {},
  };
  return authRequest.send(`/blogs/current_user?page=0&size=${size}`, config);
};

export const recordScanHistory = (id) => {
  const config = {
    method: "PUT",
    headers: {},
  };
  return authRequest.send(`/scan/${id}`, config);
};

export const getContent = (id) => {
  const config = {
    method: "GET",
    headers: {},
  };
  return authRequest.send(`/blogs/content?content_id=${id}`, config);
};
