import * as ActionTypes from '../constant/actionType';
import Immutable, { fromJS, toJS } from 'immutable';

const defaultState = fromJS({
  user: null,
  userBlogs: [],
  userBlogSize: 10,
  userBlogCount: 10,
});

const userReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GETUSERINFO:
      return state.set('user', action.payload);
    
    case ActionTypes.GETUSER_BLOGS:
        return state.set('userBlogs', action.payload);
    
    case ActionTypes.SETUSER_BLOG_SIZE:
          return state.set('userBlogSize', action.payload);
    
    case ActionTypes.UPDATEUSER_BLOG_COUNT:
          return state.set('userBlogCount', action.payload);

    default:
      return state;
  }
}

export default userReducer;