import React, { useEffect, useRef, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import _debounce from "lodash/debounce";
import {
  Card,
  CardMedia,
  CardActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import moment from "moment";

import {
  likeBlog,
  unlikeBlog,
  recordScanHistory,
  getContent,
  commentBlog,
} from "../SDK/blog";
import { getBlogData, getUserBlogs } from "../global/action";
import { useOnScreen } from "../utils/customHook";
import { randomImageList } from "./AddPostModal";

const useStyles = makeStyles((theme) => ({
  base: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiPaper-rounded": {
      borderRadius: 0,
    },
    marginBottom: 30,
  },
  root: {
    // minWidth: 480,
    cursor: "pointer",
    backgroundColor: "#fff",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    [theme.breakpoints.up("xs")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.up("md")]: {
      width: "450px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiIconButton-root": {
      transform: "translate(-10px, 0)",
      zIndex: 100,
    },
  },
  header: {
    padding: "10px",
  },
  media: {
    //height: 0,
    // paddingTop: "56.25%", // 16:9
    // height: "max-content",
    width: "100%",
    height: "300px", // 临时设置一个明确的高度来测试
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  likeBar: {
    height: "25px",
    paddingTop: "0px",
    marginTop: "8px",
    paddingLeft: "0px",
    paddingBottom: "4px",
  },
  inline: {
    display: "inline",
    fontWeight: "600",
  },
  avatar: {
    height: "40px",
  },
  links: {
    textDecoration: "none",
  },
  articleGroup: {
    marginBottom: 7,
    marginTop: 7,
  },
  more: {
    color: "rgba(0,0,0,0.5)",
  },
  post: {
    position: "absolute",
    right: 0,
    top: 7,
    color: "#2F80ED",
  },
}));

export const randomPhoto = randomImageList[1];

const DisplayCard = (props) => {
  const { item, user, dispatch, size, userBlogSize } = props;

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef();
  const addScanHistory = useOnScreen(ref);
  const hasVideo = item.videos && item.videos.length > 0;
  const hasPhotos = item.photos && item.photos.length > 0;

  const handleEndScroll = useMemo(
    () =>
      _debounce(() => {
        if (window.location.pathname === "/" && addScanHistory) {
          recordScanHistory(item._id).then((res) => {});
        }
      }, 1000),
    [addScanHistory, item._id]
  );

  useEffect(() => {
    window.onscroll = () => handleEndScroll();
    return () => (window.onscroll = null);
  });

  const unlikePost = (id) => {
    unlikeBlog(id).then(() => {
      dispatch(getBlogData(size));
      dispatch(getUserBlogs(userBlogSize));
    });
  };

  const likePost = (id) => {
    likeBlog(id).then(() => {
      dispatch(getBlogData(size));
      dispatch(getUserBlogs(userBlogSize));
    });
  };

  return (
    <div className={classes.base}>
      <Card className={classes.root} ref={ref}>
        {/* <CardMedia
          className={classes.media}
          image={item.photos[0] || randomPhoto}
          title="Photo"
          onClick={() => history.push(`/detail/${item._id}`)}
        /> */}
        <CardMedia
          className={classes.media}
          title="Media Content"
          onClick={() => history.push(`/detail/${item._id}`)}
        >
          {item.videos && item.videos.length > 0 ? (
            <video controls src={item.videos[0]}>
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={item.photos[0] || randomPhoto} alt="Photo" />
          )}
        </CardMedia>

        <CardActions className={classes.likeBar} disableSpacing>
          {item.likes.includes(user.user_id) ? (
            <IconButton
              aria-label="Like"
              style={{ color: "#f50022" }}
              onClick={() => unlikePost(item._id)}
            >
              <FavoriteIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="Like" onClick={() => likePost(item._id)}>
              <FavoriteBorderIcon />
            </IconButton>
          )}
        </CardActions>
        <div style={{ display: "flex", gap: 10 }}>
          <Typography variant="subtitle2" display="block" gutterBottom>
            {`${item.likes.length} ${item.likes.length > 1 ? "Likes" : "Like"}`}
          </Typography>
        </div>
        <div className={classes.articleGroup}>
          <Typography variant="subtitle2" color="textPrimary">
            {item.title}
          </Typography>
        </div>
      </Card>
    </div>
  );
};

const mapStoreStateToProps = (state, props) => {
  return {
    user: state.userReducer.toJS().user,
    userBlogSize: state.userReducer.toJS().userBlogSize,
    size: state.blogReducer.toJS().size,
  };
};

export default connect(mapStoreStateToProps)(DisplayCard);
