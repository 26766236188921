import store from "../store/index";
import { LOGOUT } from "../global/constant/actionType";

const _devHost =
  process.env.REACT_APP_API_URL || "https://api.thespecialdays.app";
// const _devHost = "http://localhost:5001";

let refreshing = null;

const authRequest = {
  send(endPoint, config) {
    const url = _devHost + endPoint;
    return _sendRequest(url, config, _handleResponseToJson);
  },
  sendNotToken(endPoint, config) {
    const url = _devHost + endPoint;
    return _sendRequestNotToken(url, config, _handleResponseToJsonPage);
  },
  // sendToText(endPoint, config) {
  //     const url = apn.host + api + endPoint;
  //     return _sendRequestToText(url, config);
  // },
  // sendToBlob(endPoint, config) {
  //     const url = apn.host + api + endPoint;
  //     return _sendRequestToBlob(url, config);
  // },

  checkLogin() {
    let token = localStorage.token;
    if (token) {
      const shouldUpdate = Date.now() - JSON.parse(token).createdAt > 600000;
      console.log(Date.now() - JSON.parse(token).createdAt);
      if (shouldUpdate) {
        console.log("shouldUpdate");
        return this.refreshToken(JSON.parse(token).refresh_token);
      } else {
        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  },

  refreshToken(refresh_token) {
    const url = `${_devHost}/user/refresh_token`;
    const config = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + refresh_token,
      },
    };

    return fetch(url, config)
      .then(_handleResponseToJson)
      .then((response) => {
        let newtoken = response.response;
        newtoken.credential.createdAt = Date.now();
        localStorage.token = JSON.stringify(newtoken.credential);
        return true;
      })
      .catch((err) => {
        console.log(err, err.status);
        if (err.status) {
          localStorage.clear();
          store.dispatch({ type: "logout" });
          return false;
        }
        return true;
      });
  },

  login(user_name, password) {
    const url = `${_devHost}/user/login`;
    const config = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        user_name: user_name,
        password: password,
      }),
    };
    return fetch(url, config)
      .then(_handleResponseToJson)
      .then(({ response }) => {
        let token = response.credential;
        token.createdAt = Date.now();
        localStorage.token = JSON.stringify(token);
        localStorage.user = response._id;
        localStorage.login = "success";
      });
  },

  logout() {
    return Promise.resolve(true).then((success) => {
      if (success) {
        localStorage.removeItem("token");
      }
      return success;
    });
  },

  register(email, password, username) {
    const url = `${_devHost}/user/register`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        user_name: username,
      }),
    };
    return fetch(url, config)
      .then(_handleResponseToJson)
      .then(({ response }) => {
        let token = response.user.credential;
        token.createdAt = Date.now();
        localStorage.token = JSON.stringify(token);
        localStorage.user = response.user.user_id;
      });
  },
};

export default authRequest;

export const _handleResponseToJson = (response) => {
  // console.log('_handleResponseToJson');

  if (!response.ok) {
    if (response.status >= 500) {
      console.log(new URL(response.url));
      throw {
        status: response.status,
        statusText: response.statusText,
        endpoint: new URL(response.url).pathname,
      };
    }

    if (response.status >= 400) {
      return response.json().then(
        (data) => {
          data.status = response.status;
          data.statusText = response.statusText;
          return Promise.reject(data);
        },
        () =>
          Promise.reject({
            status: response.status,
            statusText: response.statusText,
          })
      );
    }
  }

  if (response.status === 204) {
    return "OK";
  }

  return response.json().then(
    (json) => ({ response: json, headers: response.headers }),
    () => "OK"
  );
};

export const _handleResponseToJsonPage = (response) => {
  // console.log('_handleResponseToJson');

  if (!response.ok) {
    if (response.status >= 500) {
      throw {
        status: response.status,
        statusText: response.statusText,
        endpoint: new URL(response.url).pathname,
      };
    }

    if (response.status >= 400) {
      return response.json().then(
        (json) => ({ response: json, headers: response.headers }),
        () => "OK"
      );
    }
  }

  if (response.status === 204) {
    return "OK";
  }

  return response.json().then(
    (json) => ({ response: json, headers: response.headers }),
    () => "OK"
  );
};

const _retry = (url, config, refresh_token) => {
  return (
    authRequest.refreshing || authRequest.refreshToken(refresh_token)
  ).then((success) => {
    if (success) {
      let token = JSON.parse(localStorage.token);
      config.headers["Authorization"] = `Bearer ${token["access_token"]}`;
      return fetch(url, config);
    } else {
      store.dispatch({
        type: LOGOUT,
      });
    }
  });
};

const _sendRequest = (url, config, handleResponse) => {
  let token = localStorage.token;
  if (token && JSON.parse(token)) {
    token = JSON.parse(token);
    config.headers["Authorization"] = `Bearer ${token.access_token}`;
    // config.credentials = 'include';
    return (refreshing || Promise.resolve(""))
      .then(() => fetch(url, config))
      .then(handleResponse)
      .catch((err) => {
        console.error(err);
        if (err.error === "invalid_token") {
          return _retry(url, config, token.refresh_token).then(handleResponse);
        }
        throw err;
      });
  } else {
    store.dispatch({
      type: LOGOUT,
    });
    return Promise.reject("You have logged out!");
  }
};

const _sendRequestNotToken = (url, config, handleResponse) => {
  return (refreshing || Promise.resolve(""))
    .then(() => fetch(url, config))
    .then(handleResponse)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

// const _sendRequestToText = (url, config) => {
//     let token = localStorage.token;
//     if (token && JSON.parse(token)) {
//         token = JSON.parse(token);
//         config.headers['Authorization'] = `Bearer ${token.access_token}`;
//         config.credentials = 'include';
//         return (refreshing || Promise.resolve(''))
//             .then(() => fetch(url, config))
//             .then(res => res.text())
//             .catch((err) => {
//                 console.error(err);
//                 throw err;
//             });
//     } else {
//         store.dispatch({
//             type: LOGOUT,
//         });
//         return Promise.reject('You have logged out!');
//     }
// };

// const _sendRequestToBlob = (url, config) => {
//     let token = localStorage.token;
//     if (token && JSON.parse(token)) {
//         token = JSON.parse(token);
//         config.headers['Authorization'] = `Bearer ${token.access_token}`;
//         return fetch(url, config).then(res => res.blob())
//     } else {
//         store.dispatch({
//             type: LOGOUT,
//         });
//         return Promise.reject('You have logged out!');
//     }
// };
