import React, { Component } from "react";
import moment from "moment";

import './comment.scss';
import { 
	IconButton
 } from "@material-ui/core";
 import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

class CommentBox extends Component {
    constructor(props) {
      super();
      this.state = {
        comment: "",
      };
    }
        
    _addComment = (e) => {
      e.preventDefault();
      const body = {
        blog_id: this.props.id,
        message: this.state.comment,
      }
      this.props.addComment(body);
      this.setState({comment: ""})
    }
    
    render () {
      const { comments } = this.props;
      
      return(
        <div className="comment-box">
          <form className="comment-form" onSubmit={this._addComment}>
              <div className="comment-form-fields">
                <textarea 
                  placeholder="Comment" 
                  rows="4" 
                  required 
                  value = {this.state.comment}
                  onChange = {e => {
                    let value = e.target.value;
                    if(value.length <= 2000) {
                      this.setState({comment: value})
                    }
                  }}
                >
                </textarea>
              </div>
              <div className="comment-form-actions">
                <button type="submit" id="comment-submit">Post Comment</button>
              </div>
          </form>
          <h3>Comments</h3>
          <h4 className="comment-count">
            {
              comments.length === 0 ? 'No comments yet' 
              : (comments.length === 1 ? "1 comment" : `${comments.length} comments` )
            }
          </h4>
          <div className="comment-list">
            {
              comments.map((comment) => 
                <Comment 
                  author = {comment.posted_by.username} 
                  body = {comment.message} 
                  photo = {comment.posted_by.photo}
                  key = {comment._id}
                  replies = {comment.replies}
                  date = {comment.posted_at}
                  addReply = {this.props.addReply}
                  id = {comment._id}
                />
            )}
          </div>
        </div>  
      );
    } // end render
  } 
  // end CommentBox component
  
  class Comment extends React.Component {
    constructor() {
      super();
      
      this.state = {
        showReplies: false,
        addReply: false,
        reply: ""
      };
    }

    _addReply = (e) => {
      e.preventDefault();
      const body = {
        comment_id: this.props.id,
        message: this.state.reply
      }
      console.log(body)
      this.props.addReply(body);
      this.setState({showReplies: true, addReply: false, reply: ""})
    }
    
    render () {
      const { author, body, photo, replies, date } = this.props;
      const { showReplies, addReply } = this.state;
        return(
            <div className="comment">
                <div>
                    <div className="author-group">
                      <div 
                        className="avatar"
                        style={photo ? {backgroundImage: `url(${photo})`} : {backgroundColor: 'gray'}}
                      ></div>
                      <div>
                        <p className="comment-header">{author}</p>
                        <p className="date">{moment(date).format("MMMM Do YYYY")}</p>
                      </div>
                    </div>
                    <p className="comment-body">{body}</p>
                    <div style={{display: 'flex', gap: 20, marginTop: 10, marginBottom: 10, alignItems: 'center'}}>
                      <div>
                        {replies.length}
                        <IconButton
                          aria-label="Replies"
                          onClick={() => showReplies ? this.setState({showReplies: false}) : this.setState({showReplies: true})}
                        >
                          <ChatBubbleOutlineIcon />
                        </IconButton>
                      </div>
                      <div 
                        style={{cursor: 'pointer'}}
                        onClick={() => this.setState(prevState => ({addReply: !prevState.addReply}))}
                      >
                        { 'Add Reply' }
                      </div>
                    </div>
                    {
                      addReply && 
                      <form className="reply-form" onSubmit={this._addReply}>
                        <div className="comment-form-fields">
                          <textarea 
                            placeholder="Add Reply" 
                            rows="4" 
                            required 
                            value = {this.state.reply}
                            onChange = {e => {
                              let value = e.target.value;
                              if(value.length <= 2000) {
                                this.setState({reply: value})
                              }
                            }}
                          >
                          </textarea>
                        </div>
                        <div className="comment-form-actions">
                          <button type="submit" id="reply-submit">Reply</button>
                        </div>
                      </form> 
                    }
                </div>
                { showReplies &&
                  <div className="reply-list">
                    {
                      replies.length > 0 && replies.map((item, index) =>
                      <div className="reply" key = {index}>
                          <div className="author-group">
                            <div 
                              className="avatar"
                              style={item.posted_by.photo ? {backgroundImage: `url(${item.posted_by.photo})`} : {backgroundColor: 'gray'}}
                            ></div>
                            <div>
                              <p className="comment-header">{item.posted_by.username}</p>
                              <p className="date">{moment(item.posted_at).format("MMMM Do YYYY")}</p>
                            </div>
                          </div>
                          <p className="comment-body">{item.message}</p>
                      </div> 
                    )}
                  </div>
                }
            </div>
        );
    }
  }

export default CommentBox;