import FHIR from "fhirclient"
import getDiagnosis from "./getDiagnosis";
import getPatient from './getPatient';
import getBinaryInput from './getModelInput';
import postData from './postData';
import getBloodPressure from "./getBloodPressure";
import getEDVisits from "./getEDvisits";
import getPregnancyDate from "./getPregnancyDate";
import getMedications from "./getMedications";
// import getCSection from "./getCSection";

const combineData = (...conditions) => {
    let res = {}
    conditions.forEach(el => {
        res = {...res, ...el}
    })
    return res
}

export const getPrediction = async () => {
    const myApp = {}
    let result = {};
    // prepare the FHIR client
    myApp.smart = await FHIR.oauth2.ready();

    // get the patient date
    const Patient = await getPatient(myApp);
    console.log(Patient)

    //get pregnancy date
    const pregnancyDate = await getPregnancyDate(myApp).catch(e => console.log(e));
    console.log(`Pregnancy Date: ${pregnancyDate}`);

    //if patient is pregnant
    if (pregnancyDate) {
        //get diagnosis
        const condition = await getDiagnosis(myApp, pregnancyDate[0], pregnancyDate[1]);
        console.log(condition)

        //get medication
        const medication = await getMedications(myApp, pregnancyDate[0], pregnancyDate[1]);
        console.log(medication)

        //get c-section
        // const CSection = await getCSection(myApp);
        // console.log(CSection)

        //get ED visits
        const edVisits = await getEDVisits(myApp, pregnancyDate[0], pregnancyDate[1]);
        console.log(edVisits);

        //get blood pressure
        const bloodPressure = await getBloodPressure(myApp, pregnancyDate[0], pregnancyDate[1]);
        console.log(bloodPressure);

        //combine data to one object
        const combinedData = combineData(Patient.feature_dict, condition, medication, edVisits, bloodPressure);
        console.log(combinedData)

        //get binary input for calling the category API
        const binaryInput = getBinaryInput(combinedData);
        console.log(binaryInput); 

        //get prediction by calling the category API
        const res = await postData(binaryInput).catch(e => console.log(e));
        console.log(`Prediction result: ${res}`); 

        // render the result in the UI
        if(res){
            let categoryRes = res.replace(/[^a-zA-Z0-9]/g, '');
            categoryRes = categoryRes === 'healthliteracy' ? 'health_literacy' : categoryRes;
            result.category = [categoryRes];
        } 
        result.condition = combineData(condition, medication);
        result.bloodPressure = bloodPressure.blood_pressure;
        result.visits = edVisits.edvisitcount;
    }
    
    result.patient = Patient.patient;

    return result;
}