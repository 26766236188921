import authRequest from "./request";

export const getUserInfo= () => {
    const config = {
        method: 'GET',
        headers: {
        },
    };
    return authRequest.send(`/user/current-info`, config);
};

export const createUserInfo = (content) => {
    const config = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(content)
    };
    return authRequest.send('/user/info', config);
};

export const updateUserInfo = (content) => {
    const config = {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(content)
    };
    return authRequest.send('/user/info', config);
};

export const getHospitalList = () => {
    const config = {
        method: 'GET',
        headers: {
        },
    };
    return authRequest.sendNotToken(`/user/hospital-lists`, config);
}