import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { 
    FormControl, 
    Select, 
    MenuItem,  
    Button, 
    TextField, 
    Checkbox,
    Snackbar,
    FormHelperText,
    CircularProgress
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { 
    getRace,
    getMarriage,
    getMental,
    getSymptom
} from '../SDK/selectOptions';
import { createUserInfo, updateUserInfo } from '../SDK/user';
import { getUserInfo } from '../global/action';
import getBinaryInput from '../SDK/cornellApi/getModelInput';
import mapping from "../SDK/cornellApi/variable_lookup.json";
import postData from '../SDK/cornellApi/postData';
import { raceType } from '../SDK/cornellApi/getPatient';

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('xs')]: {
            gap: 5
        },
        [theme.breakpoints.up('md')]: {
            gap: 20
        },
        '& .MuiTextField-root': {
            [theme.breakpoints.up('xs')]: {
                width: 350
            },
            [theme.breakpoints.up('sm')]: {
                width: 450
            },
            [theme.breakpoints.up('md')]: {
                width: 350
            },
        },
        '& .MuiSelect-root': {
            [theme.breakpoints.up('xs')]: {
                width: 305,
            },
            [theme.breakpoints.up('sm')]: {
                width: 400
            },
            [theme.breakpoints.up('md')]: {
                width: 307
            },
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fff '
        }
    },
    fullwidthSelect: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 20,
        '& .MuiSelect-root': {
            [theme.breakpoints.up('xs')]: {
                width: 310
            },
            [theme.breakpoints.up('sm')]: {
                width: 400
            },
            [theme.breakpoints.up('md')]: {
                width: 680
            },
            [theme.breakpoints.up('lg')]: {
                width: 680
            },
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fff '
        }
    },
    formGroup: {
        margin: '10px auto'
    },
    submit: {
		margin: '50px auto',
        width: 300,
        height: 50,
	},
    label: {
        marginBottom: 0,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
}));

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 600,
        // width: 250,
      },
    },
};

const Form = (props) => {
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const { category, patient, condition, user, bloodPressure, visits} = props;

    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [nameHelper, setNameHelper] = useState('');

    const [race, setRace] = useState('');
    const [raceErr, setRaceErr] = useState(false);
    const [raceHelper, setRaceHelper] = useState('');

    const [marriage, setMarriage] = useState('');
    const [marriageErr, setMarriageErr] = useState(false);
    const [marriageHelper, setMarriageHelper] = useState('');

    const [mental, setMental] = useState([]);
    const [mentalErr, setMentalErr] = useState(false);
    const [mentalHelper, setMentalHelper] = useState('');

    const [symptom, setSymptom] = useState([]);
    const [symptomErr, setSymptomErr] = useState(false);
    const [symptomHelper, setSymptomHelper] = useState('');

    const [bloodPressure1, setBloodPressure] = useState(bloodPressure || '');
    const [bloodError, setBloodError] = useState(false);
    const [bloodHelper, setBloodHelper] = useState('');

    const [visits1, setVisits] = useState(visits || '');
    const [visitError, setVisitError] = useState(false);
    const [visitHelper, setVisitHelper] = useState('');
    
    const [marriageSelect, setMarriageSelect] = useState([]);
    const [raceSelect, setRaceSelect] = useState([]);
    const [mentalSelect, setMentalSelect] = useState();
    const [symptomSelect, setSymptomSelect] = useState([]);

    const [message, setMessage] = useState("");
    const [openMessage, setOpenMessage] = useState(false);
    const [messageType, setMessageType] = useState("");

    const [process, setProcess] = useState(false);

    const path = window.location.pathname;
    let params = new URLSearchParams(window.location.search);

    useEffect(() => {
        //get select options
        Promise.all([
            getRace(), getMarriage(), getMental(), getSymptom()
        ]).then(res => {
            setRaceSelect(res[0].response);
            setMarriageSelect(res[1].response);
            setMentalSelect(res[2].response);
            setSymptomSelect(res[3].response);
        })

        //if patient data is received from fhir, then set name, race, and marriage
        if (patient) {
            const officialName = patient.name.find((el) => el.use._attributes.value === "official" )
            const usualName = patient.name.find((el) => el.use._attributes.value === "usual" )
            console.log(officialName)
            let name;

            //set name
            name = 
                officialName ? 
                `${Array.isArray(officialName.given) ? officialName.given[0]._attributes.value : officialName.given._attributes.value} ${Array.isArray(officialName.family) ? officialName.family[0]._attributes.value : officialName.family._attributes.value}` 
                : 
                ( usualName ?  
                 `${Array.isArray(usualName.given) ? usualName.given[0]._attributes.value : usualName.given._attributes.value} ${Array.isArray(usualName.family) ? usualName.family[0]._attributes.value : usualName.family._attributes.value}`  
                    : 
                    `${Array.isArray(patient.name[0].given) ? patient.name[0].given[0]._attributes.value : patient.name[0].given._attributes.value} ${Array.isArray(patient.name[0].family) ? patient.name[0].family[0]._attribute.value : patient.name[0].family._attribute.value}` 
                );

            setName(name);
            
            //set race value
            let race = "";
            if (Array.isArray(patient.extension[0].extension)) {
                race =
                    patient.extension[0].extension.at(-1).valueString
                        ._attributes.value;
            } else {
                race =
                    patient.extension[0].extension.valueString._attributes
                        .value; // Camila Lopez, for race unknow
            }

            console.log(race)
            //decide race value basecd on raceType
            for (let i = 0; i < Object.keys(raceType).length; i++) {
                const raceCategory = Object.keys(raceType)[i];
                if (raceType[raceCategory].includes(race)){
                    race = raceCategory;
                }
            }

            // if race value is not included in raceType array, set race as other
            if(!Object.keys(raceType).includes(race)) {
                race = 'Other';
            }

            setRace(race.toLowerCase());
    
            //set marriage value
            let marriage = patient.maritalStatus.text._attributes.value;
            console.log(marriage)
            setMarriage(marriage.toLowerCase());
        }

    }, [patient])

    useEffect(()=>{
        // if condition data exists, then set mental and symptom
        if (condition) {
            let mentalRes = [];
            let symptomRes = [];
            for (const [key, value] of Object.entries(condition)) {
                const transformKey = key.replace(/ /g,"_").toLowerCase();
                if (mentalSelect && mentalSelect.includes(transformKey) && value === 1) {
                    mentalRes.push(transformKey);
                } else if(symptomSelect && symptomSelect.includes(transformKey) && value === 1) {
                    symptomRes.push(transformKey);
                }
            }
            if (mentalRes.length === 0) {
                mentalRes = ['none_of_these_applies_to_me']
            }
            if (symptomRes.length === 0) {
                symptomRes = ['none_of_these_applies_to_me']
            }
            setMental(mentalRes);
            setSymptom(symptomRes);
        }

        if (mentalSelect && symptomSelect) {
            //from Profile Page
            if(user && path === '/profile') {
                setName(user.full_name);
                setRace(user.race);
                setMarriage(user.marriage);
                setMental(user.mental);
                setSymptom(user.symptom)
                setBloodPressure(user.blood_pressure);
                setVisits(user.visits);
            }
        }

    }, [mentalSelect, symptomSelect, condition, user, path])

    const handleSubmit = async () => {
        let valid = true
        if(name && (name.length < 2 || name.length > 50)) {
            setNameErr(true);
            setNameHelper("Name needs to be between 2 to 50 letters");
            valid = false
        }
        if(!valid) {
            return
        } else {
            setNameErr(false);
            setNameHelper("");
            setRaceErr(false);
            setRaceHelper("");
            setMarriageErr(false);
            setMarriageHelper("");
            setMentalErr(false);
            setMentalHelper("");
            setSymptomErr(false);
            setSymptomHelper("");
            setBloodError(false);
            setBloodHelper("");
            setVisitError(false);
            setVisitHelper("");
        }

        setProcess(true)

        // manually-input page
        if (path === '/manually-input') {
            const user_id = localStorage.user;
            let body = {
                user_id, 
                account_id: null,
                photo: null,
                full_name: name || null,
                category: category || ["other"],
                race: race || null,
                marriage: marriage || null,
                mental: mental || null,
                symptom: symptom || null,
                blood_pressure: bloodPressure1 || null,
                visits: visits1 || null
            }
            if(params.get("code")) {
                createUserInfo(body)
                    .then(({response}) => {
                        setOpenMessage(true)
                        setMessageType('success')
                        setMessage('Profile created successfully!');
                        localStorage.removeItem('user');
                        setProcess(false)
                        setTimeout(() => {
                            history.push('/category')
                        }, 2000)
                    })
                    .catch(err => {
                        console.log(err)
                        setOpenMessage(true)
                        setMessageType('error')
                        setMessage(err.message);
                        setProcess(false)
                    })
            } else {
                //User chose to manually input info                
                //get category result
                let categoryInput = {};
                for (const [key, value] of Object.entries(mapping)) {
                    categoryInput[key] = (mental.includes(key) || symptom.includes(key)) ? 1 : 0;
                }
                categoryInput["white"] = race === "white" ? 1 : 0;
                categoryInput["asian"] = race === "asian" ? 1 : 0;
                categoryInput["single"] = marriage === "single" ? 1 : 0;
                categoryInput['blood_pressure'] =  bloodPressure1;
                categoryInput["edvisitcount"] = visits1;

                console.log(categoryInput);
                const binaryInput = getBinaryInput(categoryInput);
                let categoryRes = await postData(binaryInput).catch(e => console.log(e));
                console.log(`Prediction result: ${categoryRes}`); 
                if(categoryRes){
                    categoryRes = categoryRes.replace(/[^a-zA-Z0-9]/g, '');
                    categoryRes = categoryRes === 'healthliteracy' ? 'health_literacy' : categoryRes;
                    body.category = [categoryRes];
                } else {
                    body.category = ["other"]
                }

                createUserInfo(body)
                    .then(({response}) => {
                        setOpenMessage(true)
                        setMessageType('success')
                        setMessage('Profile created successfully!');
                        localStorage.removeItem('user');
                        setProcess(false)
                        setTimeout(() => {
                            history.push('/category')
                        },2000)
                    })
                    .catch(err => {
                        console.log(err)
                        setOpenMessage(true)
                        setMessageType('error')
                        setMessage(err.message);
                        setProcess(false)
                    })
            }
        } else {
            // profile page
            //this needs to be checked, if reset category data here, user self select category info will not be saved

            //get category result
            let categoryInput = {};
            for (const [key, value] of Object.entries(mapping)) {
                categoryInput[key] = (mental.includes(key) || symptom.includes(key)) ? 1 : 0;
            }
            categoryInput["white"] = race === "white" ? 1 : 0;
            categoryInput["single"] = marriage === "single" ? 1 : 0;
            categoryInput['blood_pressure'] =  bloodPressure1;
            categoryInput["edvisitcount"] = visits1;

            const binaryInput = getBinaryInput(categoryInput);
            let categoryRes = await postData(binaryInput).catch(e => console.log(e));
            if(categoryRes){
                categoryRes = categoryRes.replace(/[^a-zA-Z0-9]/g, '');
                categoryRes = categoryRes === 'healthliteracy' ? 'health_literacy' : categoryRes;
            } 
            console.log(`Prediction result: ${categoryRes}`); 

            let body = {
                _id: user._id,
                user_id: user.user_id,
                account_id: null,
                photo: user.photo,
                full_name: name || null,
                category: categoryRes ? [categoryRes] : user.category,
                race: race || null,
                marriage: marriage || null,
                mental: mental || null,
                symptom: symptom || null,
                blood_pressure: bloodPressure1 || null,
                visits: visits1 || null
            }
    
            updateUserInfo(body)
            .then(({response}) => {
                setOpenMessage(true)
                setMessageType('success')
                setMessage('Success!');
                setProcess(false)
                props.dispatch(getUserInfo())
            })
            .catch(err => {
                console.log(err)
                setOpenMessage(true)
                setMessageType('error')
                setMessage(err.message);
                setProcess(false)
            })
        }
    }

    const renderMultiple = (list, value) => {
        let selected = [];
        let display;
        if(list && value && value.length > 0) {
            list.forEach(item => {
                if(value.includes(item)){
                    selected.push(item.replace(/_/g, ' '))
                }
            })
            display = selected.join(', ');
        } else {
            display = <span style={{color: '#787878'}}>Please Select</span>
        }
        return display
    }
    
    return (
        <>
            <form className={classes.form} >
                <div className={classes.row}>
                    <div>
                        <p className={classes.label}>Name</p>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="name"
                            name="name"
                            value={name}
                            onChange = {e => {
                                let value = e.target.value;
                                setName(e.target.value)
                                if(value){
                                    setNameErr("");
                                    setNameHelper(false);
                                }
                            }}
                            error = {nameErr}
                            helperText = {nameHelper}
                        />
                    </div>
                    <div>
                        <p className={classes.label}>Race</p>
                        <FormControl variant="outlined" style={{marginTop: 15}} error={raceErr}>
                            <Select
                                labelId="race"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                value={race}
                                onChange = {e => {
                                    let value = e.target.value;
                                    setRace(value)
                                    if(value){
                                        setRaceErr("");
                                        setRaceHelper(false);
                                    }
                                }}
                                renderValue={(value) => {
                                    return value
                                }}
                            >
                                {
                                    raceSelect&&raceSelect.map((item, index) => 
                                    <MenuItem value={item} key={index}>{item.replace(/_/g, ' ')}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{raceHelper}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div className={classes.row}>
                    <div>
                        <p className={classes.label}>Marriage</p>
                        <FormControl variant="outlined" style={{marginTop: 15}} error={marriageErr}>
                            <Select
                                labelId="marriage"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                value={marriage}
                                onChange = {e => {
                                    let value = e.target.value;
                                    setMarriage(value);
                                    if(value){
                                        setMarriageErr("");
                                        setMarriageHelper(false);
                                    }
                                }}
                                // renderValue={(value) => this.renderSingleSelect(industryList, value)}
                            >
                                {
                                    marriageSelect&&marriageSelect.map((item, index) => 
                                    <MenuItem value={item} key={index}>{item.replace(/_/g, ' ')}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{marriageHelper}</FormHelperText>
                        </FormControl>
                    </div>
                    <div>
                        <p className={classes.label}>How is your mental health ?</p>
                        <FormControl variant="outlined" style={{marginTop: 15}} error={mentalErr}>
                            <Select
                                multiple
                                labelId="mentalHealth"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                value={mental|| []}
                                onChange = {e => {
                                    let value = e.target.value;
                                    const special = 'none_of_these_applies_to_me'
                                    if( value[0] === special && value.length > 1){
                                        setMental(value.filter(el => el !== special))
                                    } else if (value[value.length-1] === special){
                                        setMental([special])
                                    } else {
                                        setMental(value)
                                    }
                                    if (value.length !== 0){
                                        setMentalErr("");
                                        setMentalHelper(false);
                                    }
                                }}
                                renderValue={(value) => renderMultiple(mentalSelect, value)}
                            >
                                {
                                    mentalSelect&&mentalSelect.map((item, index) => 
                                    <MenuItem value={item} key={index}>
                                        <Checkbox 
                                            checked={mental&&mental.indexOf(item) > -1} 
                                        />
                                        {item.replace(/_/g, ' ')}
                                    </MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{mentalHelper}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div className={classes.fullwidthSelect}>
                </div>
                <div className={classes.fullwidthSelect}>
                    <div className={classes.formGroup}>
                        <p className={classes.label}>Are you experiencing any of the below in this pregnancy?</p>
                        <FormControl variant="outlined" style={{marginTop: 15, marginBottom: 10}} error={symptomErr}>
                            <Select
                                multiple
                                labelId="symptom"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                value={symptom || []}
                                onChange = {e => {
                                    let value = e.target.value;
                                    const special = 'none_of_these_applies_to_me'
                                    if( value[0] === special && value.length > 1){
                                        setSymptom(value.filter(el => el !== special))
                                    } else if (value[value.length-1] === special){
                                        setSymptom([special])
                                    } else {
                                        setSymptom(value)
                                    }
                                    if (value.length !== 0){
                                        setSymptomErr("");
                                        setSymptomHelper(false);
                                    }
                                }}
                                renderValue={(value) => renderMultiple(symptomSelect, value)}
                            >
                                {
                                    symptomSelect&&symptomSelect.map((item, index) => 
                                    <MenuItem value={item} key={index}>
                                         <Checkbox 
                                            checked={symptom&&symptom.indexOf(item) > -1} 
                                        />
                                        {item.replace(/_/g, ' ')}
                                    </MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{symptomHelper}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div className={classes.row}>
                    <div>
                        <p className={classes.label}>Diastolic blood pressure in third trimester</p>
                        <TextField
                            error = {bloodError}
                            helperText = {bloodHelper}
                            variant="outlined"
                            margin="normal"
                            required
                            id="diastolic"
                            name="diastolic"
                            value={bloodPressure1}
                            onChange = {e => {
                                let value = e.target.value;
                                setBloodPressure(value);
                                if (!Number.isInteger(Number(value)) || (value < 1 || value > 300)){
                                    setBloodError(true);
                                    setBloodHelper("Please enter a valid number");
                                } else {
                                    setBloodError(false);
                                    setBloodHelper("");
                                }
                            }}
                            type="number"
                            min = "1"
                            max = "300"
                        />
                    </div>
                    <div>
                        <p className={classes.label}>Number of ED visits since pregnant</p>
                        <TextField
                            error = {visitError}
                            helperText = {visitHelper}
                            variant="outlined"
                            margin="normal"
                            required
                            id="EDvisits"
                            name="EDvisits"
                            value={visits1}
                            onChange = {e => {
                                let value = e.target.value;
                                setVisits(value)
                                if (!Number.isInteger(Number(value)) || (value < 0 || value > 100)){
                                    setVisitError(true);
                                    setVisitHelper("Please enter a valid number");
                                } else {
                                    setVisitError(false);
                                    setVisitHelper("");
                                }
                            }}
                            type="number"
                            min = "0"
                            max = "99"
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        onClick = {handleSubmit}
                    >
                        Submit
                        {process && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                        )}
                    </Button>
                </div> 
            </form>
            <Snackbar
				open={openMessage}
				autoHideDuration={3000}
				anchorOrigin = {{
					vertical: 'bottom',
				    horizontal: 'right'
				}}
			>
				<Alert severity={messageType}>{message}</Alert>
			</Snackbar>
        </>
    )
}

const mapStoreStateToProps = (state, props) => {
    return { 
        user: state.userReducer.toJS().user,
        loggedin: state.loggedin
    };
};

export default connect(
    mapStoreStateToProps
)(Form);