import { combineReducers } from 'redux';
import loggedin from './login';
import blogReducer from './blog';
import userReducer from './user';
import pageReducer from './page';

export default combineReducers({
  loggedin,
  blogReducer,
  userReducer,
  pageReducer
});