import * as ActionTypes from '../constant/actionType';

export default function(state = { login: false, authorities: null }, action) {
    switch (action.type) {
      case ActionTypes.LOGIN:
        return true;
      case ActionTypes.LOGOUT:
        return false;
      case ActionTypes.LOGIN_CHECKED:
        return action.logged;
      default:
        return state;
    }
}
