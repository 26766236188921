import * as convert from "xml-js";
import moment from "moment";

// parse ED visits data
const parseEDVisits= (xml, pregnancyStart, pregnancyEnd) => {
    var res = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 4 })
    );
    const entryList = res.Bundle.entry;
    let edVisits = 0;
    for (let i = 0; i < entryList.length; i++) {
        let encounter = entryList[i].resource.Encounter;
        if( encounter){
            const startDate = encounter.period && encounter.period.start._attributes.value;

            // check if encounter is during pregnancy and is an ED visit
            if( 
                startDate&&
                encounter.location &&
                encounter.location.location &&
                encounter.location.location.display &&
                encounter.location.location.display._attributes.value.toLowerCase().includes('emergency')
                && 
                moment(startDate).isAfter(moment(pregnancyStart)) && (pregnancyEnd ? moment(startDate).isBefore(moment(pregnancyEnd)) : true) 
            ){
                edVisits += 1
            }
        }
    }
    return { edvisitcount: edVisits }
};

//get ED visits
const getEDVisits = async (myApp, pregnancyStart, pregnancyEnd) => {
    const URL =
        myApp.smart.state.serverUrl +
        "/Encounter?subject=Patient/" +
        myApp.smart.patient.id

    const res = await fetch(URL, {
        headers: {
            // Accept: "application/json+fhir",
            Authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });
    const result = await res.text();
    const EDVisits = parseEDVisits(result, pregnancyStart, pregnancyEnd);
    return EDVisits;
};

export default getEDVisits;