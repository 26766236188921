import React, { useEffect } from "react";
import FHIR from "fhirclient";
import CircularProgress from '@material-ui/core/CircularProgress';

const Launch = () => {
    useEffect(() => {
        // FHIR.oauth2.authorize({
        //   'client_id': '2122bda5-7f2f-4893-ad50-afb2cc2eb603',
        //   'scope':  'PATIENT.READ',
        //   'redirect_uri': 'http://localhost:3000/manually-input'
        // })
        FHIR.oauth2.authorize({
          'client_id': 'd9fa1be4-6d19-4bc4-aaa6-e0cddc2f492f',
          'scope':  'PATIENT.READ',
          'redirect_uri': 'https://www.thespecialdays.app/manually-input'
        })
        // FHIR.oauth2.authorize({
        //   'client_id': '4d1a7d5a-6068-4902-b911-9249b0a6dab6',
        //   'scope':  'PATIENT.READ',
        //   'redirect_uri': 'https://epic.ichiyoz.repl.co/app.html'
        // })
    })

    return (
        <div style={{margin: '50px auto', textAlign: 'center'}}>
            <CircularProgress/>
        </div>
    )
}

export default Launch;
