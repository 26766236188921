import React, { useEffect } from 'react';
import store from './store/index';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import Routing from "./routes/Routing";

import './App.css';

const theme = createTheme({
});

function App() {

  return (
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Routing />
        </Provider>
    </MuiThemeProvider>
  );
}

export default App;
