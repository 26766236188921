const postData = async (data) => {
    const URL = process.env.REACT_APP_CORNELL_API_URL || 'http://35.153.52.66/shap'

    const res = await fetch(URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
    
    const result = await res.text();
    return result;
};

export default postData;