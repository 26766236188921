import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';

import Navbar from '../components/Navbar';
import Ocean from '../assets/background/ocean.jpg'

const useStyles = makeStyles((theme) => ({
	image: {
		backgroundSize: "cover",
		backgroundColor: "#fafafa",
		backgroundImage: `url(${Ocean})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
        paddingBottom: 80
	},
    paper: {
		marginTop: 140,
        padding: 50,
        color: '#4F4F4F',
		backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('md')]: {
            width: 800,
            height: 800
        },
	},
    title: {
        textAlign: 'center',
        fontSize: 24,
        margin: '20px auto'
    },
    content: {
        marginBottom: 20,
        fontSize: 14,
    },
    contactInfo: {
        textAlign: 'left',
        fontSize: 14,
        marginTop: 5,
        marginBottom: 5
    }
}));

const About = () => {
    const classes = useStyles();
    return (
        <>
            <Navbar />
            <Grid 
                container 
                className={classes.image} 
                justifyContent = "center"
            >
                <div className={classes.paper}>
                    <div className={classes.title}>
                        About Us
                    </div>
                    <p className={classes.content}>
                        We are a group of researchers and clinicians at Weill Cornell Medicine. We developed this tool so that pregnant patients are supported throughout their journey through the power of information.

                    </p>
                    <p className={classes.content}>
                        Our educational materials are provided by Healthwise®. Healthwise®’s content development and review processes involve daily monitoring for changes in guidelines and medical practice, planning and research to leverage evidence-based resources and vet content updates through user research, and writing and review by their team of health literacy, clinical, and subject matter experts.
                    </p>
                    <p className={classes.content}>
                        Using a proprietary algorithm that we created, we tailor the educational materials to you that we believe may be relevant to your current health. The results of this questionnaire are not professional medical advice, diagnosis, or treatment, but for informational purposes only. The results from this website are not intended to be a diagnosis. The relevance of the content is based on data reported by users and not independently verified for accuracy and eligibility. You should still follow your care providers' diagnosis, treatment, and guidance, and contact your care providers for any medical assistance and emergencies. Never disregard professional medical advice or delay in seeking treatment because of something you have read in this website. Only a licensed medical doctor can make accurate diagnosis after conducting the necessary tests.
                    </p>
                    <div>
                        <p className={classes.contactInfo}>
                            Contact Us at:
                        </p>
                        <p className={classes.contactInfo}>
                            Yiye Zhang, PhD, MS
                        </p>
                        <a 
                            href="yiz2014@med.cornell.edu" 
                            className={classes.contactInfo}
                            style={{color: '#2F80ED'}}
                        >
                            yiz2014@med.cornell.edu
                        </a>

                    </div>                         
                </div>
            </Grid>
        </>
    )
}

export default About
