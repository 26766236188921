import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

// Material-UI Components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Divider } from "@material-ui/core";
import moment from "moment";

import {
  getBlogDetail,
  commentBlog,
  replyComment,
  likeBlog,
  unlikeBlog,
} from "../SDK/blog";
import Navbar from "../components/Navbar";
import { checklogin, getUserInfo } from "../global/action";
import Comment from "../components/comment/comment";
import { randomPhoto } from "../components/Card";

// General style
const useStyles = makeStyles((theme) => ({
  base: {
    margin: "90px auto 20px",
    [theme.breakpoints.up("xs")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: 800,
    },
  },
  back: {
    marginBottom: 20,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 10,
    [theme.breakpoints.up("xs")]: {
      marginLeft: 20,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 30,
    },
  },
  contentBox: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      padding: 30,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 40,
    },
  },
  coverImg: {
    width: "100%",
    height: 400,
    backgroundPosition: "center",
    backgroundSize: "cover",
    margin: "20px auto",
  },
  authorGroup: {
    display: "flex",
    gap: 20,
    marginBottom: 40,
  },
  avatar: {
    height: 50,
    width: 50,
    borderRadius: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  authorName: {
    fontSize: 16,
    marginBottom: 5,
  },
  date: {
    color: "rgba(0, 0, 0, 0.7)",
  },
  title: {
    fontSize: 32,
  },
  text: {
    padding: 20,
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: 16,
  },
  iconGroup: {
    display: "flex",
    gap: 20,
  },
}));

const Detail = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState(null);
  const [id, setID] = useState("");

  useEffect(() => {
    const blogId = window.location.pathname.slice(8);
    setID(blogId);
    props
      .dispatch(checklogin())
      .then(() => {
        if (props.loggedin) {
          props.dispatch(getUserInfo());
          getBlogDetail(blogId).then(({ response }) => {
            console.log(response);
            setData(response.blog_detail);
          });
        } else {
          history.push("/login");
        }
      })
      .catch((err) => {
        history.push("/login");
      });
  }, []);

  const addComment = (body) => {
    commentBlog(body).then((res) => {
      console.log(res);
      getBlogDetail(id).then(({ response }) => {
        setData(response.blog_detail);
      });
    });
  };

  const addReply = (body) => {
    replyComment(body).then((res) => {
      console.log(res);
      getBlogDetail(id).then(({ response }) => {
        setData(response.blog_detail);
      });
    });
  };

  const unlikePost = (id) => {
    unlikeBlog(id).then(() => {
      getBlogDetail(id).then(({ response }) => {
        setData(response.blog_detail);
      });
    });
  };

  const likePost = (id) => {
    likeBlog(id).then(() => {
      getBlogDetail(id).then(({ response }) => {
        setData(response.blog_detail);
      });
    });
  };

  return (
    <>
      <Navbar />
      <div className={classes.base}>
        <div className={classes.back} onClick={() => history.goBack()}>
          <ArrowBackIcon style={{ color: "rgba(0,0,0,0.7)" }} />
          BACK
        </div>
        {data && (
          <div className={classes.contentBox}>
            <div className={classes.authorGroup}>
              <div
                style={
                  data.posted_by.photo
                    ? { backgroundImage: `url(${data.posted_by.photo})` }
                    : { backgroundColor: "gray" }
                }
                className={classes.avatar}
              ></div>
              <div>
                <div className={classes.authorName}>
                  {data.posted_by.username}
                </div>
                <div className={classes.date}>
                  {moment(data.created_at).format("MMMM Do YYYY")}
                </div>
              </div>
            </div>
            <div className={classes.title}>{data.title}</div>
            <div className={classes.coverImg}>
              {data.videos && data.videos.length > 0 ? (
                <video controls style={{ width: "100%", height: "400px" }}>
                  <source src={data.videos[0]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${data.photos[0] || randomPhoto})`,
                    height: "100%",
                    backgroundSize: "cover",
                  }}
                />
              )}
            </div>
            <div style={{ marginBottom: 40 }}>
              <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
            </div>
            <div className={classes.iconGroup}>
              <div>
                {data.likes.length}
                {data.likes.includes(props.user.user_id) ? (
                  <IconButton
                    aria-label="Like"
                    style={{ color: "#f50022" }}
                    onClick={() => unlikePost(data._id)}
                  >
                    <FavoriteIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="Like"
                    onClick={() => likePost(data._id)}
                  >
                    <FavoriteBorderIcon />
                  </IconButton>
                )}
              </div>
              <div>
                {data.comments.length}
                <IconButton aria-label="Comment">
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </div>
            </div>
            <Divider />
            <Comment
              comments={data.comments}
              id={data._id}
              addComment={addComment}
              addReply={addReply}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStoreStateToProps = (state, props) => {
  return {
    user: state.userReducer.toJS().user,
    loggedin: state.loggedin,
  };
};

export default connect(mapStoreStateToProps)(Detail);
