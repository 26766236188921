import authRequest from "./request";

export const getCategory = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return authRequest.sendNotToken('/blogs/category', config);
};

export const getRace = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return authRequest.sendNotToken('/user/race', config);
};

export const getMarriage = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return authRequest.sendNotToken('/user/marriage', config);
};

export const getMental = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return authRequest.sendNotToken('/user/mental', config);
};

export const getSymptom = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return authRequest.sendNotToken('/user/symptom', config);
};



