import React, { useState,  useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

// Material-UI Components
import { makeStyles } from "@material-ui/core/styles";
import { 
    AppBar, 
    Toolbar, 
    IconButton,
    MenuItem,  
    Menu
} from '@material-ui/core';
// Material-UI Icons
import MoreIcon from "@material-ui/icons/MoreVert";

import logo from '../assets/logo/specialdays.png';
import { logout, UpdateModalStatus } from "../global/action";

import { Color } from "../assets/color";
import AddPostModal from "./AddPostModal";

const useStyles = makeStyles((theme) => ({
    logo: {
		width: 130,
        cursor: 'pointer'
	},
	root: {
		width: "100%",
	},
	inline: {
		display: "inline",
	},
	grow: {
		flexGrow: 1,
        '& .MuiPaper-elevation4': {
            boxShadow: 'none',
            borderBottom: 'solid rgba(0, 0, 0, 0.1)'
        }
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
		fontFamily: "Grand Hotel, cursive",
		color: "rgba(0, 0, 0, 0.54)",
	},
	sectionDesktop: {
		display: "none",
        gap: 30,
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
    navItem: {
        color: Color.darkGrey,
        cursor: 'pointer',
        height: 64,
        display: 'flex',
        alignItems: 'center',
    },
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
    mobileNavItem: {
        width: '120px',
        textAlign: 'center'
    },
}));


const Navbar = props => {
	const history = useHistory();
	const classes = useStyles();

    // const [active, setActive] = useState(window.location.pathname)
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if(!localStorage.token ) {
            history.push('/login')
        }
    }, [])

    useEffect(() => {
        if(props.modalOpen) {
            setMobileMoreAnchorEl(null);
        }
    },[props.modalOpen])

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleLogOut = () => {
		localStorage.clear();
        props.dispatch(logout());
		history.push("/login");
	};

    const handleNav = (path) => {
        history.push(path);
        // setActive(path);
    }

    const handleOpenModal = () => {
        setOpenModal(true);
        props.dispatch(UpdateModalStatus(true))
    }

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
            className={classes.mobileNav}
		>
			<MenuItem onClick={handleOpenModal}>
				<p className={classes.mobileNavItem}>
                    Add Post
                </p>
			</MenuItem>
			<MenuItem to="/about" onClick = {() => handleNav('/about')}>
				<p className={classes.mobileNavItem}>
                    About Us
                </p>
			</MenuItem>
            <MenuItem to="/profile" onClick = {() => handleNav('/profile')}>
				<p className={classes.mobileNavItem}>
                    Profile
                </p>
			</MenuItem>
			<MenuItem onClick={handleLogOut}>
				<p className={classes.mobileNavItem}>
                    LogOut
                </p>
			</MenuItem>
		</Menu>
	);

	return (
        <>
            <nav>
                <div className={classes.grow}>
                    <AppBar position="static" style={{ "backgroundColor": "#ffffff", position: 'fixed', top: 0 }}>
                        <Toolbar>
                            <div className={classes.logo} onClick={() => history.push('/')}>
                                <img src={logo} alt="logo" style={{width: '90%'}}/>
                            </div> 
                            <div className={classes.grow} />
                            <div className={classes.sectionDesktop}>
                                <div 
                                    className={classes.navItem}
                                    onClick={handleOpenModal}
                                >
                                    Add Post
                                </div>
                                <div
                                    className={classes.navItem}
                                    onClick = {() => handleNav('/about')}
                                    // style={active === '/about' ? { borderBottom: '2px solid #2F80ED' } : {}}
                                >
                                    About Us
                                </div>
                                <div
                                    className={classes.navItem}
                                    onClick = {() => handleNav('/profile')}
                                    // style={active === '/profile' ? { borderBottom: '2px solid #2F80ED' } : {}}
                                >
                                    Profile
                                </div>
                                <div
                                    className={classes.navItem}
                                    onClick={handleLogOut}
                                >
                                    Logout
                                </div>
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon style={{ "color": "rgba(0, 0, 0, 0.54)" }} />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {renderMobileMenu}
                </div>
		    </nav>
            <AddPostModal
                openModal = {openModal}
                setOpenModal = {setOpenModal}
            />
        </>
	);
};

const mapStoreStateToProps = (state) => {
    return {
        loggedin: state.loggedin,
        user: state.userReducer.toJS().user,
        modalOpen: state.pageReducer.toJS().modalOpen
    };
};


export default connect(mapStoreStateToProps)(Navbar);
