import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Navbar from "../components/Navbar";
import Form from "../components/patientInfoForm";
import DisplayCard from "../components/userProfileCard";
import { uploadToServer } from "../SDK/blog";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { updateUserInfo } from "../SDK/user";
import {
  getUserInfo,
  getUserBlogs,
  changeUserBlogSize,
} from "../global/action";

const useStyles = makeStyles((theme) => ({
  profileImgSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "50px auto 30px",
  },
  profilePlaceholder: {
    width: 80,
    height: 80,
    borderRadius: "100%",
    color: "#9d9c9c",
    cursor: "pointer",
  },
  profileImg: {
    width: 80,
    height: 80,
    borderRadius: "100%",
    objectPosition: "center",
    cursor: "pointer",
    objectFit: "cover",
  },
  cardGroup: {
    margin: "40px auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 20,
    [theme.breakpoints.up("xs")]: {
      width: "90vw",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: 925,
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("lg")]: {
      width: 1025,
      justifyContent: "space-between",
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const Profile = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const loader = useRef(null);
  const { user, dispatch, modalOpen, userBlogs, userBlogSize } = props;
  const [value, setValue] = useState(0);
  const [photo, setPhoto] = useState(null);
  const [cardNum, setCardNum] = useState(userBlogSize);

  useEffect(() => {
    if (!localStorage.token) {
      history.push("/login");
    } else {
      dispatch(getUserInfo()).catch((err) => {
        history.push("./login");
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      setPhoto(user.photo);
    }
  }, [user]);

  useEffect(() => {
    if (value === 0) {
      dispatch(getUserBlogs(cardNum)).catch((err) => {
        if (err.msg === "Token has expired") {
          history.push("/login");
        }
      });
    }
  }, [value, cardNum, modalOpen]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setCardNum((prev) => prev + 10);
      dispatch(changeUserBlogSize(userBlogSize + 10));
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateProfilePhoto = (e) => {
    let file = e.target.files[0];
    var data = new FormData();
    data.append("file", file);
    uploadToServer(data).then(({ response }) => {
      setPhoto(response.url);
      let body = {
        _id: user._id,
        user_id: user.user_id,
        account_id: null,
        photo: response.url,
        full_name: user.full_name,
        category: user.category,
        race: user.race,
        marriage: user.marriage,
        mental: user.mental,
        symptom: user.symptom,
        blood_pressure: user.blood_pressure,
        visits: user.visits,
      };
      updateUserInfo(body).then((res) => {
        console.log(res);
        props.dispatch(getUserInfo());
      });
    });
  };

  return (
    <>
      <Navbar />
      {user && (
        <div style={{ marginTop: 90 }}>
          <div className={classes.profileImgSection}>
            {modalOpen ? (
              <img
                src={photo || user.photo}
                alt="profile"
                className={classes.profileImg}
              />
            ) : photo || user.photo ? (
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  id="contained-button-file"
                  onChange={updateProfilePhoto}
                />
                <label
                  htmlFor="contained-button-file"
                  // className={classes.profilePlaceholder}
                >
                  <img
                    src={photo || user.photo}
                    alt="profile"
                    className={classes.profileImg}
                  />
                </label>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  id="contained-button-file"
                  onChange={updateProfilePhoto}
                />
                <label
                  htmlFor="contained-button-file"
                  className={classes.profilePlaceholder}
                >
                  <AccountCircleIcon className={classes.profilePlaceholder} />
                </label>
              </div>
            )}
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Posts" />
            <Tab label="Info" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div className={classes.cardGroup}>
              {userBlogs && userBlogs.length > 0 ? (
                userBlogs.map((item, index) => (
                  <DisplayCard item={item} user={user} key={index} />
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "100px auto",
                    fontSize: 18,
                  }}
                >
                  <div>No Post created yet</div>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div style={{ marginTop: 40 }}>
              <Form user={props.user} />
            </div>
          </TabPanel>
        </div>
      )}
      {value === 0 && <div ref={loader}></div>}
    </>
  );
};

const mapStoreStateToProps = (state) => {
  const loggedin = state.loggedin;
  return {
    loggedin,
    user: state.userReducer.toJS().user,
    userBlogs: state.userReducer.toJS().userBlogs,
    userBlogCount: state.userReducer.toJS().userBlogCount,
    userBlogSize: state.userReducer.toJS().userBlogSize,
    modalOpen: state.pageReducer.toJS().modalOpen,
  };
};

export default connect(mapStoreStateToProps)(Profile);
