import * as convert from "xml-js";
import getMedicationCode from "./getMedicationCode";
import code_dict_med from './code_dict_med.json';
import moment from "moment";
import MedicationRef from "./testData/medication_ref";

// parse medication data
const parseMed= async (xml, myApp, pregnancyStart, pregnancyEnd) => {
    const res = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 4 })
    );
    const Bundle = res.Bundle;
    let entryList = Bundle.entry || [];
    console.log(Bundle)
    let medicationDate = [];
    let feature_dict = {};

    // get medication code and period
    if (entryList.length > 0) {
        for (let i = 0; i < entryList.length; i++) {
            let medicationRequest = entryList[i].resource.MedicationRequest;
            if (medicationRequest) {
                const referenceID = medicationRequest.medicationReference.reference._attributes.value;
                const code = await getMedicationCode(myApp, referenceID)
                const period = medicationRequest.authoredOn._attributes.value;
                code && period && medicationDate.push({code: code, period: period})
            }
        }
        console.log(entryList)
    } else if (Bundle) {
        if (Array.isArray(Bundle)) {
            for (let i = 0; i < Bundle.length; i++) {
                if (Array.isArray(Bundle[i].entry)) {
                    Bundle[i].entry.map(el => entryList.push(el))
                } else {
                    entryList.push(Bundle[i].entry)
                }
            }
            console.log(entryList)
            if (entryList.length > 0) {
                // get medication code and period
                for (let i = 0; i < entryList.length; i++) {
                    let medicationRequest = entryList[i].resource.MedicationRequest;
                    if (medicationRequest) {
                        const referenceID = medicationRequest.medicationReference.reference._attributes.value;
                        const code = await getMedicationCode(myApp, referenceID)
                        const period = medicationRequest.authoredOn._attributes.value;
                        code && period && medicationDate.push({code: code, period: period})
                    }
                }
            }
        } 
    }
    console.log(medicationDate)

    // compare medicationDate code with code_dict_med and pregnancyDate to see if there's any match, if yes, return 1
    if (medicationDate.length > 0) {
        for (let i = 0; i < Object.keys(code_dict_med).length; i++) {
            const code = Object.keys(code_dict_med)[i];
            if (medicationDate.map(o => o.code).includes(code)) {
                const conditionObj = medicationDate.find(o => o.code === code);
                if(conditionObj){
                    const conditionDate = conditionObj.period;
                    if (
                        (conditionDate) &&
                        moment(conditionDate).isAfter(moment(pregnancyStart)) && (pregnancyEnd ? moment(conditionDate).isBefore(moment(pregnancyEnd)) : true)
                    ) {
                        feature_dict[code_dict_med[code]] = 1;
                    }
                }
            } 
        }
    }
    
    // if no match in keys of code_dict_med, return 0
    for (let i = 0; i < Object.keys(code_dict_med).length; i++) {
        const code = Object.keys(code_dict_med)[i];
        if (!feature_dict.hasOwnProperty(code_dict_med[code])) {
            feature_dict[code_dict_med[code]] = 0;
        }
    }
    return feature_dict;
};


// get medication data from fhir server
const getMedications = async (myApp, pregnancyStart, pregnancyEnd) => {
    const URL =
        myApp.smart.state.serverUrl +
        "/MedicationRequest?patient=" +
        myApp.smart.patient.id +
        "&code=http://www.whocc.no/atc/A03";

    const res = await fetch(URL, {
        headers: {
            // Accept: "application/json+fhir",
            Authorization:
                "Bearer " + myApp.smart.state.tokenResponse.access_token,
        },
    });
    const medicationInfo = await res.text();

    const medInfo = parseMed(medicationInfo, myApp, pregnancyStart, pregnancyEnd);
    // const medInfo = parseMed(MedicationRef, myApp, pregnancyStart, pregnancyEnd);
    return medInfo;
};

export default getMedications;
