import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Material-UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { getPrediction } from "../../SDK/cornellApi/getPrediction";
import specialdays from '../../assets/logo/specialdays.png';
import Form from "../../components/patientInfoForm";
import Ocean from '../../assets/background/ocean.jpg'

// General Styles
const useStyles = makeStyles((theme) => ({
	logo: {
		display: 'flex',
        justifyContent: 'center',
        width: 350,
        margin: '0 auto 60px',
        [theme.breakpoints.up('xs')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0 auto 60px',
        },
	},
	paper: {
        marginTop: 80,
		backgroundColor: '#fff',
        [theme.breakpoints.up('xs')]: {
            width: '100vw',
            marginTop: 0
        },
        [theme.breakpoints.up('sm')]: {
            width: '90vw',
            marginTop: 80
        },
        [theme.breakpoints.up('lg')]: {
            width: 1200,
            marginTop: 80,
        },
	},
	image: {
		backgroundSize: "cover",
		backgroundColor: "#fafafa",
		backgroundImage: `url(${Ocean})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

const Input = () => {
	const classes = useStyles();
    const history = useHistory();
	const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState(null);
    const [condition, setCondition] = useState(null);
    const [category, setCategory] = useState("");
    const [bloodPressure, setBloodPressure] = useState("");
    const [visits, setVisits] = useState(null);

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        const user_id = localStorage.user;
        if(user_id) {
            if(params.get("code")) {
                setLoading(true)
                getPrediction().then(res => {
                    console.log(res)
                    setLoading(false);
                    setCategory(res.category);
                    setPatient(res.patient);
                    setCondition(res.condition);
                    setBloodPressure(res.bloodPressure);
                    setVisits(res.visits);
                }).catch(err => {
                    setLoading(false)
                    console.log(err)
                })
            }
        } else {
            history.push('/')
        }
    }, [])

    // useEffect(() => {
    //     let params = new URLSearchParams(window.location.search);
    //     if(params.get("code")){
    //         setLoading(true);
    //         getAccess(params.get("code"))
    //         .then(() => {
    //             setLoading(false);
    //             // console.log(xmlDoc.getElementsByTagName("name")[0].childNodes[1].attributes.value.nodeValue);
    //             // console.log(xmlDoc.getElementsByTagName("extension")[0].childNodes[0].childNodes[0].childNodes[2].attributes.value.nodeValue);
    //             // console.log(xmlDoc.getElementsByTagName("maritalStatus")[0].childNodes[0].attributes.value.nodeValue);
    //             // setName(xmlDoc.getElementsByTagName("name")[0].childNodes[1].attributes.value.nodeValue);
    //             // setRace(xmlDoc.getElementsByTagName("extension")[0].childNodes[0].childNodes[0].childNodes[2].attributes.value.nodeValue);
    //             // setMarriage(xmlDoc.getElementsByTagName("maritalStatus")[0].childNodes[0].attributes.value.nodeValue);
    //             // const pregnancyStart = await getPragnancyStart();
    //             // console.log(pregnancyStart)

    //         })
    //     }
    // }, [])

	return (
        <>
            {
                loading ? 
                <div style={{margin: '50px auto', textAlign: 'center'}}>
                    <CircularProgress/>
                </div> :
                <Grid 
                    container 
                    className={classes.image} 
                    justifyContent = "center"
                >
                    <div
                        className={classes.paper}
                    >
                            <div className={classes.paper}>
                                <div className={classes.logo}>
                                    <img src={specialdays} alt="test" style={{width: '50%'}}/>
                                </div> 
                                    <Form 
                                        patient = {patient}
                                        category = {category}
                                        condition = {condition}
                                        bloodPressure = {bloodPressure}
                                        visits = {visits}
                                    />                                                              
                            </div>
                    </div>
                </Grid>
            }
        </>
	);
};

export default Input;