import * as ActionTypes from "../constant/actionType";
import Immutable, { fromJS, toJS } from "immutable";

const defaultState = fromJS({
  modalOpen: false,
  blogUpdateTrigger: false,
});

const pageReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ActionTypes.UPDATEMODAL_STATUS:
      return state.set("modalOpen", action.payload);

    case ActionTypes.SET_BLOG_UPDATE_TRIGGER:
      return state.set("blogUpdateTrigger", action.payload);

    default:
      return state;
  }
};

export default pageReducer;
