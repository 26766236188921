import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

// Material-UI Components
import {
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Container,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import logo from "../../assets/logo/specialdays.png";
import { register } from "../../global/action";
import { isEmail, isPassword } from "../../utils/validation";
import ExampleImg1 from "../../assets/background/leaf_bg.jpg";
// General Styles
const useStyles = makeStyles((theme) => ({
  logo: {
    margin: "60px auto 20px",
    width: 200,
  },
  paper: {
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiButton-fullWidth": {
      backgroundColor: "#2F80ED",
      color: "#fff",
    },
    "& .Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  image: {
    backgroundSize: "cover",
    backgroundColor: "#fafafa",
    backgroundImage: `url(${ExampleImg1})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  link: {
    marginTop: theme.spacing(1),
    display: "block",
    textAlign: "center",
    color: "#2F80ED",
  },
}));

const Register = () => {
  const history = useHistory();
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState(false);
  const [usernameHelper, setUsernameHelper] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [emailHelper, setEmailHelper] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [valid, setValid] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const handlePassword = (e) => {
    let value = e.target.value;
    setPassword(value);
    if (!isPassword(value)) {
      setPasswordErr(true);
      setPasswordHelper(
        "Please enter at least one number, one uppercase letter and one lowercase letter"
      );
      setValid(false);
    } else {
      setPasswordErr(false);
      setPasswordHelper("");
      setValid(true);
    }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    setEmail(value);
    if (!isEmail(value)) {
      setEmailErr(true);
      setEmailHelper("Please enter a valid email address");
      setValid(false);
    } else {
      setEmailErr(false);
      setEmailHelper("");
      setValid(true);
    }
  };

  const handleUserName = (e) => {
    let value = e.target.value;
    setUsername(value);
    if (value.length < 4 || value.length > 20) {
      setUsernameErr(true);
      setUsernameHelper("username needs to be between 4 to 20 characters");
      setValid(false);
    } else {
      setUsernameErr(false);
      setUsernameHelper("");
      setValid(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register({
      email: email,
      password: password,
      username: username,
    })
      .then(() => {
        setOpenMessage(true);
        setMessageType("success");
        setMessage("Account created successfully!");
        setTimeout(() => {
          history.push("/consent");
        }, 1000);
      })
      .catch((err) => {
        setOpenMessage(true);
        setMessageType("error");
        setMessage(err.message);
        console.log(err);
      });
  };

  return (
    <Grid container>
      <Grid className={classes.image} item sm={4} md={6} />
      <Grid item xs={12} sm={8} md={6}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className={classes.logo}>
              <img src={logo} alt="test" style={{ width: "90%" }} />
            </div>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                onChange={handleUserName}
                value={username}
                error={usernameErr}
                helperText={usernameHelper}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handleEmail}
                value={email}
                error={emailErr}
                helperText={emailHelper}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                autoComplete="current-password"
                onChange={handlePassword}
                value={password}
                error={passwordErr}
                helperText={passwordHelper}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                fullWidth
                variant="contained"
                className={classes.submit}
                disabled={!valid}
                type="submit"
              >
                Create Account
              </Button>
            </form>
            <div className={classes.link}>
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Already have an account? Sign in
              </Link>
            </div>
          </div>
          <Snackbar
            open={openMessage}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity={messageType}>{message}</Alert>
          </Snackbar>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Register;
