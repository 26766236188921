import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

// Material-UI Components
import DisplayCard from "../components/Card";
import Navbar from "../components/Navbar";
import {
  getBlogData,
  getUserInfo,
  changeBlogPageSize,
  setBlogUpdateTrigger,
} from "../global/action";

// General style

const Home = (props) => {
  const history = useHistory();
  const { blogData, user, dispatch, size } = props;
  const loader = useRef(null);

  const [cardNum, setCardNum] = useState(10);

  useEffect(() => {
    if (!localStorage.token) {
      history.push("/login");
    }
    dispatch(getUserInfo())
      .then(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("login");
      })
      .catch((err) => {
        if (err.message === "The user doesn't have user info.") {
          history.push("./manually-input");
        } else {
          history.push("./login");
        }
      });
  }, []);

  useEffect(() => {
    dispatch(getBlogData(cardNum)).catch((err) => {
      if (err.msg === "Token has expired") {
        history.push("/login");
      }
    });
  }, [cardNum]);

  useEffect(() => {
    if (props.blogUpdateTrigger) {
      dispatch(getBlogData());
      dispatch(setBlogUpdateTrigger(false));
    }
  }, [props.blogUpdateTrigger, dispatch]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setCardNum((prev) => prev + 10);
      dispatch(changeBlogPageSize(size + 10));
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <>
      <Navbar />
      <div style={{ marginTop: 80 }}>
        {user &&
          blogData &&
          blogData.map((item, index) => (
            <div key={index}>
              <DisplayCard item={item} user={user} />
            </div>
          ))}
      </div>
      <div ref={loader}></div>
    </>
  );
};

const mapStoreStateToProps = (state, props) => {
  const loggedin = state.loggedin;
  return {
    blogData: state.blogReducer.toJS().blogData,
    user: state.userReducer.toJS().user,
    count: state.blogReducer.toJS().count,
    size: state.blogReducer.toJS().size,
    loggedin,
  };
};

export default connect(mapStoreStateToProps)(Home);
